import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Options,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import {
  EyeInvisibleOutlined,
  ClockCircleOutlined,
  EyeTwoTone,
  InfoCircleOutlined,
  LoadingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../../helpers/Notifications";
import { useSelector } from "react-redux";
import AddUserApi from "../../../api/User/AddUserApi";
import { GetExistingApi } from "../../../api/User/GetExistingApi";

function AddUserModal(props) {
  const [submit, setSubmit] = useState(false);
  const userState = useSelector((state) => state.userState.value);
  const { Option } = Select;

  const [username, setUsername] = useState("");
  const [userAvailable, setUserAvailable] = useState("");

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  const roles = ["user", "admin"];

  async function onFinish(values) {
    setSubmit(true);
    const apiResponse = await AddUserApi(
      values.username,
      values.email,
      values.role
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", apiResponse.message);
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't add category try again later."
      );
    }
    handleCancel();
  }

  async function checkUser() {
    const response = await GetExistingApi(username);

    if (response.success) {
      setUserAvailable("not available");
    } else {
      setUserAvailable("available");
    }
    //   setTimeout(() => {
    //     setUserAvailable('');
    // }, 3000);
    // }
  }

  return (
    <Modal
      title="Add new User"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="addUser"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          className={"pt-2"}
          label="Username :"
          name="username"
          rules={[
            {
              required: true,
              message: "Please enter username!",
            },
          ]}
        >
          <div className="flex gap-1 ">
            <Input
              style={{ width: "100%" }}
              suffix={`${
                userAvailable === "available"
                  ? "Available"
                  : userAvailable === "not available"
                  ? "Not available"
                  : ""
              }`}
              status={`${
                userAvailable === "available"
                  ? "warning"
                  : userAvailable === "not available"
                  ? "error"
                  : ""
              }`}
              prefix={<UserOutlined />}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Username"
            />
           
           
            

            <Button style={{ width: 80 }} onClick={checkUser}>
              {" "}
              Check
            </Button>
          </div>

        
        </Form.Item>

        <Form.Item
          label="Email Address :"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter Email Address!",
            },
          ]}
        >
          <Input placeholder="Email Address" />
        </Form.Item>

        <Form.Item
          label="Role :"
          name="role"
          initialValue={"user"}
          rules={[
            {
              required: true,
              message: "Please enter role!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select position"
            optionFilterProp="children"
          >
            {roles.map((position) => {
              return (
                <Option key={position} value={position}>
                  {position}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-40 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Add User"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddUserModal;
