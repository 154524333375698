import { Button, Form, Input, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../../helpers/Notifications";
import AddCategoryApi from "../../../api/Categories/AddCategoryApi";
import { LoadingOutlined } from "@ant-design/icons";

function AddCategoryModal(props) {
  const [submit, setSubmit] = useState(false);
  const { Option } = Select;

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  async function onFinish(values) {
    setSubmit(true);
    const apiResponse = await AddCategoryApi(
      values.name,
      "Good",
      values.position
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", apiResponse.message);
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't add category try again later."
      );
    }
    handleCancel();
  }

  return (
    <Modal
      title="Add new category"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="addCategory"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Name :"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter category name!",
            },
          ]}
        >
          <Input placeholder="Category name" />
        </Form.Item>
        <Form.Item
          label="Position :"
          name="position"
          rules={[
            {
              required: true,
              message: "Please enter position number!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select position"
            optionFilterProp="children"
          >
            {Array.from(
              { length: props.data?.totalCount + 1 },
              (_, i) => i + 1
            )?.map((position) => {
              return (
                <Option key={position} value={position}>
                  {position}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-28 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Add Category"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddCategoryModal;
