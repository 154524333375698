import { Button, Form, Input, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import { STATUS } from "../../../helpers/constants";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;

function CategoriesFilterModal(props) {
  const [submit, setSubmit] = useState(false);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  const onFinish = (values) => {
    setSubmit(true);
    props.handleOk(values);
    handleCancel();
  };

  return (
    <Modal
      title="Filter Categories"
      open={props.visible}
      onOk={props.handleOk}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="categoriesFilter"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item label="Name :" name="name">
          <Input placeholder="Category name" />
        </Form.Item>

        <Form.Item label="Status :" name="status">
          <Select placeholder="Select status">
            <Option value={STATUS.Available}>Available</Option>
            <Option value={STATUS.Hidden}>Hide</Option>
          </Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={props.handleCancel}>
              Cancel
            </Button>
            <Button
              className="w-28 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Filter"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CategoriesFilterModal;
