import { Button, Form, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { GetTableApi } from "../../api/Tables/GetTableApi";
import ChangeQrTableApi from "../../api/Qr/ChangeQrTableApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";


const { Option } = Select;

function ChangeTableModal(props) {
  const [submit, setSubmit] = useState(false);
  const [table, setTable] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.visible) {
      getTables();
      form.setFields([
        {
          name: "tableId",
          value: props.data?.table?.name || "",
        },
      ]);
    }
  }, [props.visible]);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
    form.resetFields();
  }

  async function getTables() {
    const apiResponse = await GetTableApi();
    if (apiResponse.success) {
      setTable(apiResponse.data);
    }
  }

  async function onFinish(value) {
    const apiResponse = await ChangeQrTableApi(props.data?.id, value.tableId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Qr table was changed successfully");
      props.handleOk();
    } else {
      openNotificationError("bottomRight", "Couldn't change. Try again later.");
    }
  }



  return (
    <Modal
      title="Change Table"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="changeTable"
        onFinish={onFinish}
        form={form}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item label="Table name :" name="tableId">
          <Select
            allowClear
            showSearch
            placeholder="Select Table"
            optionFilterProp="children"
            rules={[
              {
                required: true,
                message: "Please select table!",
              },
            ]}
            onChange={(value) => {
              {
                form.setFieldsValue({
                  tableId: value,
                });
              }
            }}
          >
            {table?.map((table) => {
              return (
                <Option key={table.id} value={table.id}>
                  {table.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-40 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangeTableModal;
