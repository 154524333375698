import { Button, Form, Input, InputNumber, Modal, Spin } from "antd";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import AddOrderDiscountApi from "../../../api/Orders/AddOrderDiscountApi";
import { useLocation } from "react-router-dom";
import RemoveOrderDiscountApi from "../../../api/Orders/RemoveOrderDiscountApi";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../../../helpers/currencyFormat";

function AddDiscountModal(props) {
  const [submit, setSubmit] = useState(false);
  const curLocation = useLocation();
  const businessState = useSelector((state) => state.businessState.value);

  const path = curLocation.pathname;
  const id = path.split("/");
 

  async function addDiscount(values) {
    const apiResponse = await AddOrderDiscountApi(id[2], values.discount);
    if (apiResponse.data) {
      props.handleOk();
    }
  }

  async function removeDiscount(values) {
    const apiResponse = await RemoveOrderDiscountApi(id[2]);
    if (apiResponse.data) {
      props.handleOk();
      setSubmit(false);
    }
  }

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }
  return (
    <Modal
      title="Add discount"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="addDiscount"
        onFinish={addDiscount}
        autoComplete="off"
        layout="horizontal"
        requiredMark={false}
      >
        <div className="mb-2">Total amount: {currencyFormatter(props.data?.subTotal, businessState?.currency?.symbol)}</div>
        <div className="flex gap-2">
          <Form.Item label="Discount percentage :" name="discount">
            <InputNumber min={0} placeholder="%" />
       
          </Form.Item>
          <div>
            { props.data?.discount !== 0 &&
                
                <Button
                className="w-36 "
                htmlType="primary"
                onClick={removeDiscount} > 
                Remove discount
              </Button>
              
            }
          </div>
        </div>

        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-40 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Add"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddDiscountModal;
