import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Form, InputNumber, Spin, Upload, message, Select } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import ImgCrop from "antd-img-crop";
import { useDispatch, useSelector } from "react-redux";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import EditPreferencesApi from "../../api/Business/EditPreferencesApi";
import { UploadImagesApi } from "../../api/UploadImagesApi";
import { businessDetails } from "../../features/BusinessSlice";
import { GetCurrencyApi } from "../../api/Business/GetCurrencyApi";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import RemoveBusinessLogoApi from "../../api/Business/RemoveBusinessLogoApi";
const { Option } = Select;

function Preferences() {
  const [submit, setSubmit] = useState(false);
  const [logo, setLogo] = useState("");
  const [newLogo, setNewLogo] = useState("");
  const [currency, setCurrency] = useState([]);
  const [removeModal, setRemoveModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const businessState = useSelector((state) => state.businessState.value);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const proHeight = document.body.clientHeight;
  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Preferences</div>
    </>
  );

  const fields = [
    {
      name: ["currency"],
      value: businessState?.currencyId || "",
    },
    {
      name: ["tax"],
      value: businessState?.tax || "",
    },
    {
      name: ["serviceCharge"],
      value: businessState?.serviceCharge || "",
    },
  ];

  async function onFinish(values) {
    setSubmit(true);

    const apiResponse = await EditPreferencesApi(
      businessState?.id,
      logo ? logo : values.logo,
      values.currency,
      values.tax,
      values.serviceCharge
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      dispatch(businessDetails(apiResponse.data));
      openNotificationSuccess(
        "bottomRight",
        "Preferences successfully updated"
      );
      setSubmit(false);
    }
  }


  async function uploadLogo(file, name) {
    setSubmit(true);
    const apiResponse = await UploadImagesApi(file, name, "logo");
    if (apiResponse.success) {
      setLogo(apiResponse?.url);
    }
    setSubmit(false);
  }

  function beforeCrop(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.info("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.info("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUploadLogo = async (info) => {
    await uploadLogo(info, businessState.id);
    getBase64(info, (logo) => {
      setNewLogo(logo);
    });
    return false;
  };

  async function getCurrency() {
    const apiResponse = await GetCurrencyApi();
    if (apiResponse.success) {
      setCurrency(apiResponse.data);
      setLoading(false);
    }
  }

  useEffect(() => {
    getCurrency();
  }, []);

  async function removeBusinessLogo() {
    const apiResponse = await RemoveBusinessLogoApi(businessState.id);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      dispatch(businessDetails(apiResponse.data));
      setLogo("");
      setNewLogo("");
      openNotificationSuccess("bottomRight", "Business Logo Removed");
    } else {
      openNotificationError("bottomRight", "Couldn't remove. Try again later.");
    }
  }

  return (
    <>
      <div className="ml-4">
        <DashboardBreadcrumb left={left} />
        <Content className="bg-white p-4"
          style={{
            height: proHeight - 122,
          }}
        >
          <div className="custom-scroll">
            <div >
              {loading ? (
                <div>
                  <Spin
                    size="large"
                    className="flex justify-center items-center"
                  />
                </div>
              ) : (
                <Form
                  name="preferences"
                  onFinish={onFinish}
                  scrollToFirstError
                  layout="vertical"
                  fields={fields}
                  form={form}
                  requiredMark={false}
                >
                  <div className="flex items-center">
                    <div className="mt-6 mb-2 flex justify-center items-center h-16 w-64">
                      <Form.Item
                        label="logo:"
                        name="logo"
                        initialValue={businessState?.logo}
                      >
                        <ImgCrop
                          rotate
                          aspect={4 / 1}
                          modalOk="Save"
                          beforeCrop={beforeCrop}
                          modalClassName="imageCrop"
                        >
                          <Upload
                            name="logo"
                            listType="picture-circle"
                            showUploadList={false}
                            beforeUpload={beforeUploadLogo}
                          >
                            {newLogo || logo || businessState?.logo ? (
                              <div className="relative h-full w-full overflow-hidden cursor-auto ">
                                <img
                                  src={
                                    newLogo
                                      ? newLogo
                                      : logo
                                      ? logo
                                      : businessState?.logo
                                  }
                                  alt="avatar"
                                  className="h-16 w-64 bg-gray-200 "
                                />
                                <div className="absolute top-0 left-0 w-full h-full z-10 hover:bg-gray-900 hover:bg-opacity-50 transition-opacity duration-300">
                                  <div className="h-full w-full flex items-center justify-center opacity-0 hover:opacity-100  transition-opacity duration-300">
                                    <EditOutlined className="text-red-400 text-2xl cursor-pointer" />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="flex justify-center items-center h-16 w-64 bg-gray-200">
                                <span className="text-center ">
                                  + Upload Logo
                                </span>
                              </div>
                            )}
                          </Upload>
                        </ImgCrop>
                      </Form.Item>
                    </div>
                    <div>
                      {newLogo || logo || businessState?.logo ? (
                        <div className="mt-3 ml-3">
                          <Button
                            danger
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => setRemoveModal(true)}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <Form.Item label="Currency:" name="currency">
                    <Select
                      style={{
                        width: 120,
                      }}
                      allowClear
                      showSearch
                      placeholder="Select Currency"
                    >
                      {currency?.map((currency) => {
                        return (
                          <Option key={currency.id} value={currency.id}>
                            {currency.abbreviation}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Tax:" name="tax">
                    <InputNumber
                      className="w-36"
                      placeholder="%"
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      min={0}
                    />
                  </Form.Item>

                  <Form.Item label="Service charge:" name="serviceCharge">
                    <InputNumber
                      className="w-36"
                      placeholder="%"
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      min={0}
                    />
                  </Form.Item>

                  <Button
                    className="w-40 bg-blue-500"
                    type="primary"
                    htmlType="submit"
                  >
                    {submit ? (
                      <Spin
                        size="small"
                        indicator={
                          <LoadingOutlined style={{ color: "#fff" }} spin />
                        }
                      />
                    ) : (
                      "Update preference"
                    )}
                  </Button>
                </Form>
              )}
            </div>
          </div>
        </Content>
      </div>
      <ConfirmationModal
        visible={removeModal}
        handleCancel={() => setRemoveModal(false)}
        handleOk={() => {
          removeBusinessLogo();
          setRemoveModal(false);
        }}
        text="Are you sure want to Remove Business Logo?"
        title="Remove Business Logo"
      />
    </>
  );
}

export default Preferences;
