import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Spin,
  Tooltip,
} from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import Link from "antd/es/typography/Link";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../../helpers/Notifications";
import EditModifierGroupApi from "../../../api/modifierGroups/EditModifierGroupApi";

function EditModifierGroupModal(props) {
  const [form] = Form.useForm();
  const [submit, setSubmit] = useState(false);
  const [isRequired, setIsRequired] = useState(true);
  const plainOptions = ["Required", "Optional"];
  const [modifiers, setModifiers] = useState([]);
  const [deleteModifiers, setDeleteModifiers] = useState([]);

  useEffect(() => {
    setModifiers(props.data?.modifier || []);

    form.setFieldsValue({
      modifierGroupName: props.data?.name,
      isRequired: props.data?.type,
      minimum: props.data?.minimum,
      maximum: props.data?.maximum,
      modifiers: props.data?.modifier || [],
    });
  }, [props.data, form]);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
    form.resetFields();
    setModifiers(props.data?.modifier || []);
  }

  async function onFinish(values) {
    const isRequired = values.isRequired === "Required";
    setSubmit(true);
    const apiResponse = await EditModifierGroupApi(
      props.data?.id,
      values.modifierGroupName,
      isRequired,
      values.minimum,
      values.maximum,
      values.modifiers,
      deleteModifiers
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", apiResponse.message);
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't edit modifier group try again later."
      );
    }
    handleCancel();
  }


  const handleRemoveModifier = (index) => {
    if (modifiers.length > 1) {
      setModifiers((prevModifiers) => {
        const deletedModifier = prevModifiers[index];
        const modifiedModifiers = prevModifiers.filter((_, i) => i !== index);
        setDeleteModifiers((prevDeletedModifiers) => [
          ...prevDeletedModifiers,
          deletedModifier.id,
        ]);

        form.setFieldsValue({
          modifiers: modifiedModifiers,
        });
  
        return modifiedModifiers;
      });
    }
  };

  useEffect(() => {
    setModifiers((prevModifiers) =>
      prevModifiers.filter((modifier) => !deleteModifiers.includes(modifier.id))
    );
  }, [deleteModifiers]);


  const handlePreselectChange = (index) => {
    setModifiers((prevModifiers) => {
      return prevModifiers.map((modifier, i) => {
        if (i === index) {
          return { ...modifier, isPreSelected: true };
        } else {
          return { ...modifier, isPreSelected: false };
        }
      });
    });
    form.setFieldsValue({
      modifiers: form.getFieldValue("modifiers").map((modifier, i) => {
        if (i === index) {
          return { ...modifier, isPreSelected: true };
        } else {
          return { ...modifier, isPreSelected: false };
        }
      }),
    });
  };

  const renderModifierRow = (modifier, index) => (
    <Form.Item key={index} className="m-0 p-0">
      <Row gutter={24} align="stretch" justify="center">
        <Col span={8}>
          <Form.Item
            label={index === 0 ? "Modifier Name" : undefined}
            name={['modifiers', index, 'name']}
            rules={[
              {
                required: true,
                message: 'Modifier name is required!',
              },
            ]}
          >
            <Input placeholder="Modifier Name" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={index === 0 ? "Price" : undefined}
            name={['modifiers', index, 'price']}
            rules={[
              {
                required: true,
                message: 'Price is required!',
              },
            ]}
          >
            <InputNumber placeholder="Price" min={0} className="w-[130px]" />
          </Form.Item>
        </Col>
        <Col span={4} align="middle">
          <Form.Item
            label={index === 0 ? "Preselect" : undefined}
            name={['modifiers', index, 'isPreSelected']}
          >
            <Radio
              checked={modifier.isPreSelected || false}
              onChange={() => handlePreselectChange(index)}
            ></Radio>
          </Form.Item>
        </Col>
        <Col span={4} className="items-stretch">
          <Form.Item label={index === 0 ?  "Delete" : undefined} name={['modifiers', index]}>
            {modifiers.length > 1 ? (
              <Button
                size="small"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveModifier(index)}
              />
            ) : (
              <Tooltip title="Cannot delete the last modifier.">
                <Button size="small" icon={<DeleteOutlined />} disabled />
              </Tooltip>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );

  const handleAddModifier = () => {
    const newModifier = {
      id: null,
      name: "",
      price: "",
      isPreSelected: false,
    };
    setModifiers((prevModifiers) => [...prevModifiers, newModifier]);
    form.setFieldsValue({
      modifiers: [...form.getFieldValue("modifiers"), newModifier],
    });
  };


  return (
    <Modal
      title="Edit Modifier Group"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="editModifierGroup"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={true}
        initialValues={{
          modifierGroupName: props.data?.name,
          isRequired: props.data?.type,
          minimum: props.data?.minimum,
          maximum: props.data?.maximum,
          modifiers: props.data?.modifier || [],
        }}
      >
        <Form.Item
          label="Modifier Group Name"
          name="modifierGroupName"
          rules={[
            {
              required: true,
              message: "Modifier group name is required!",
            },
          ]}
        >
          <Input placeholder="Modifier Group Name" />
        </Form.Item>
        <Form.Item
          label="Modifier Group Type"
          name="isRequired"
          rules={[
            {
              required: true,
              message: "Modifier group type is required!",
            },
          ]}
        >
          <Radio.Group
            options={plainOptions}
            onChange={(e) => setIsRequired(e.target.value === "Required")}
            value={isRequired ? "Required" : "Optional"}
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label="Minimum"
              name="minimum"
              rules={[
                {
                  required: false,
                  message: "Minimum is required!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const modifierType = getFieldValue("isRequired");
                    if (modifierType === "Required" && (value === undefined || value < 1)) {
                      return Promise.reject(
                        "Minimum should be 1 for Required modifier type!"
                      );
                    } 
                    if (modifierType === "Optional" && value === undefined) {
                      return Promise.reject(
                        "Please provide a Minimum value!"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber
                placeholder="Minimum"
                min={0}
                className="w-[130px]"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Maximum"
              name="maximum"
              rules={[
                {
                  required: false,
                  message: "Please provide a Maximum value",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const min = getFieldValue("minimum");
                    if (value !== undefined && (isNaN(value) || value < 0)) {
                      return Promise.reject(
                        "Maximum must be a positive number"
                      );
                    } else if (
                      min !== undefined &&
                      value !== undefined &&
                      value < min
                    ) {
                      return Promise.reject(
                        "Maximum cannot be less than the minimum"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber placeholder="Maximum" className="w-[130px]" min={0} />
            </Form.Item>
          </Col>
        </Row>
        {modifiers.map((modifier, index) => renderModifierRow(modifier, index))}
        <Link type="link" onClick={handleAddModifier}>
          + Add New Modifier
        </Link> 
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-40 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditModifierGroupModal;
