import {
  Badge,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import EditItemApi from "../../../api/Items/EditItemApi";
import ImgCrop from "antd-img-crop";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../../helpers/Notifications";
import { GetCategoryApi } from "../../../api/Categories/GetCategoryApi";
import { UploadImagesApi } from "../../../api/UploadImagesApi";
import GetModifierGroupByNameApi from "../../../api/modifierGroups/SearchModifierGroupsApi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { generateImageAI } from "../../../api/GenerateImageApi";
const { Option } = Select;
const { TextArea } = Input;

function EditItemModal(props) {
  const [form] = Form.useForm();
  const [submit, setSubmit] = useState(false);
  const [category, setCategory] = useState();
  const [itemImage, setItemImage] = useState(props.data?.imageUrl);
  const [newItemImage, setNewItemImage] = useState("");
  const [modifierRows, setModifierRows] = useState([]);
  const [modifierGroups, setModifierGroups] = useState(
    props.data?.modifierGroups || []
  );
  const [isFixed, setIsFixed] = useState(props.data?.isFixed);
  const [removedModifierGroups, setRemovedModifierGroups] = useState([]);
  const plainOptions = ["Fixed", "Variant"];
  const [imageOption, setImageOption] = useState("Upload");
  const [itemName, setItemName] = useState(props.data?.name);
  const [generatedImage, setGeneratedImage] = useState(false);
  const businessState = useSelector((state) => state.businessState.value);
  const [imageGenerationCount, setImageGenerationCount] = useState(3);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    setIsFixed(props.data?.isFixed);
  }, [props.data?.isFixed]);

  useEffect(() => {
    setItemName(props.data?.name);
    setModifierRows(props.data?.modifierGroups || []);
  }, [props.data]);

  useEffect(() => {
    fetchModifierGroups();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: props.data?.name,
      description: props.data?.description,
      price: props.data?.price,
      categoryId: props.data?.category?.id,
      imageUrl: props.data?.imageUrl,
      isFixed: props.data?.isFixed ? "Fixed" : "Variant",
      modifierGroups: props.data?.modifierGroups || [],
    });
  }, [props.data, form]);

  const fetchModifierGroups = async () => {
    try {
      const apiResponse = await GetModifierGroupByNameApi("");
      setModifierGroups(apiResponse.data);
    } catch (error) {
      console.error("Error fetching modifier groups: ", error);
    }
  };

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
    setIsFixed(props.data?.isFixed);
    setItemName(props.data?.name);
    setItemImage(props.data?.imageUrl);
    setImageOption("Upload");
    setModifierRows(
      props.data?.isFixed ? [] : props.data?.modifierGroups || []
    );
    setImageGenerationCount(3);
    setGeneratedImage(false);
  }

  async function onFinish(value) {
    const newModifierRows = modifierRows.filter((row) => row.isNew);
    const submitData = {
      ...value,
      modifierGroups: newModifierRows, // Send only new modifier groups
    };

    const isFixed = value.isFixed === "Fixed";
    setSubmit(true);
    const apiResponse = await EditItemApi(
      props.data?.id,
      value.name,
      isFixed,
      value.description,
      value.price,
      value.categoryId,
      itemImage ? itemImage : value.imageUrl,
      removedModifierGroups,
      submitData.modifierGroups
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.error.message);
    } else if (apiResponse.success) {
      openNotificationSuccess(
        "bottomRight",
        "Item details successfully updated"
      );
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't edit menu-item. Try again later."
      );
    }
    handleCancel();
  }

  async function getCategory() {
    const apiResponse = await GetCategoryApi();
    if (apiResponse.message === "success") {
      setCategory(apiResponse.data);
    }
  }

  function beforeCrop(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.info("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.info("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUploadImage = async (info) => {
    await uploadImage(info, info.name);
    getBase64(info, (imageUrl) => {
      setNewItemImage(imageUrl);
    });
    return false;
  };

  async function uploadImage(file, name) {
    setSubmit(true);
    const apiResponse = await UploadImagesApi(file, name, "profile");
    if (apiResponse.success) {
      setItemImage(apiResponse?.url);
    }
    setSubmit(false);
  }

  const handleIsFixedChange = (e) => {
    const isSwitchingToVariant = e.target.value === "Variant";
    setIsFixed(!isSwitchingToVariant);

    if (isSwitchingToVariant) {
      handleAddRow();
    } else {
      setModifierRows([]);
    }
  };

  const handleRemoveRow = (index) => {
    const removedModifierGroup = modifierRows[index];
    const updatedRows = modifierRows
      .filter((_, i) => i !== index)
      .map((row, i) => ({ ...row, position: i + 1 }));

    setModifierRows(updatedRows);

    form.setFieldsValue({
      modifierGroups: updatedRows,
    });

    setRemovedModifierGroups((prevRemovedGroups) => [
      ...prevRemovedGroups,
      removedModifierGroup,
    ]);
  };

  const handleAddRow = () => {
    setModifierRows([
      ...modifierRows,
      { position: modifierRows.length + 1, isNew: true },
    ]);
  };

  const selectModifierGroup = (index, selectedModifier) => {
    setModifierRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        id: selectedModifier.id,
        name: selectedModifier.name,
        position: index + 1,
        ...(updatedRows[index].isNew && { isNew: true }),
      };
      return updatedRows;
    });

    form.setFieldsValue({
      modifierGroups: modifierRows.map((row, rowIndex) => {
        if (rowIndex === index) {
          return {
            id: selectedModifier.id,
            name: selectedModifier.name,
            position: index + 1,
          };
        }
        return row;
      }),
    });
  };

  const sortedModifiers = modifierRows.sort((a, b) => a.position - b.position);

  const renderRows = () => {
    return sortedModifiers.map((value, index) => (
      <Form.Item key={index}>
        <Row gutter={16} justify="space-around" className="h-[30px]">
          <Col span={1}>
            <Form.Item name={["modifierGroups", index, "position"]}>
              {value.position}
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name={["modifierGroups", index, "name"]}
              initialValue={value.name}
              rules={[
                {
                  required: true,
                  message: "Modifier Group is required.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const modifierGroups = getFieldValue("modifierGroups");
                    if (
                      !modifierGroups ||
                      modifierGroups.length === 0 ||
                      !value
                    ) {
                      return Promise.resolve();
                    }
                    const otherNames = new Set(
                      modifierGroups
                        .filter((_, i) => i !== index && modifierGroups[i].name)
                        .map((row) => row.name)
                    );
                    if (otherNames.has(value)) {
                      return Promise.reject("Duplicate modifier groups");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                placeholder="Search"
                onSelect={(selectedId) => {
                  const selectedModifier = modifierGroups.find(
                    (modifier) => modifier.id === selectedId
                  );
                  selectModifierGroup(index, selectedModifier);
                }}
              >
                {modifierGroups.map((result) => (
                  <Option key={result.id} value={result.id}>
                    {result.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={1} className="mt-1">
            <Tooltip
              title={
                !isFixed && modifierRows.length === 1
                  ? "At least one modifier is required for variant item"
                  : ""
              }
            >
              <Button
                size="small"
                danger
                onClick={() => handleRemoveRow(index)}
                icon={<DeleteOutlined />}
                disabled={modifierRows.length === 1 && !isFixed}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>
    ));
  };

  const handleImageOptionChange = (e) => {
    setImageOption(e.target.value);
  };

  const generateImage = async (description, imageName) => {
    if (imageGenerationCount === 0) {
      return;
    }
    setGeneratedImage(true);
    setImageGenerationCount((prevCount) => prevCount - 1);
    const apiResponse = await generateImageAI(
      description,
      businessState.id,
      imageName
    );
    if (apiResponse?.Img_url) {
      setGeneratedImage(false);
      setItemImage(apiResponse?.Img_url);
    }
  };

  return (
    <Modal
      title="Edit item"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        form={form}
        name="editItem"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={true}
        initialValues={{
          name: props.data?.name,
          description: props.data?.description,
          price: props.data?.price,
          categoryId: props.data?.category?.id,
          imageUrl: props.data?.imageUrl,
          isFixed: props.data?.isFixed ? "Fixed" : "Variant",
        }}
      >
        <Form.Item
          label="Name:"
          name="name"
          rules={[
            {
              required: true,
              message: "Item name is required!",
            },
          ]}
        >
          <Input placeholder="Item name" />
        </Form.Item>
        <Form.Item
          label="Category :"
          name="categoryId"
          rules={[
            {
              required: true,
              message: "Category is required!",
            },
          ]}
        >
          <Select allowClear showSearch placeholder="Select category">
            {category?.map((categ) => {
              return (
                <Option key={categ.id} value={categ.id}>
                  {categ.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Description :" name="description">
          <TextArea placeholder="Item description" rows={3} />
        </Form.Item>
        <Form.Item
          label="Image :"
          name="imageUrl"
          rules={[
            {
              required: false,
              message: "Image is Required!",
            },
          ]}
        >
          <Radio.Group
            onChange={handleImageOptionChange}
            value={imageOption}
            style={{ marginBottom: 10 }}
          >
            <Radio value="Upload">Upload</Radio>
            <Radio value="AI">Generate using AI</Radio>
          </Radio.Group>
          {imageOption === "Upload" ? (
            <ImgCrop
              rotate
              aspect={4 / 3}
              modalOk="Save"
              beforeCrop={beforeCrop}
              modalClassName="imageCrop"
            >
              <Upload
                showUploadList={false}
                name="imageUrl"
                listType="picture-card"
                beforeUpload={beforeUploadImage}
              >
                {newItemImage || itemImage || props?.data?.imageUrl ? (
                  <img
                    src={
                      newItemImage
                        ? newItemImage
                        : itemImage
                        ? itemImage
                        : props?.data?.imageUrl
                    }
                    alt="avatar"
                    className="h-[100px] w-[100px] object-cover bg-gray-200 border-2 border-white rounded-lg "
                  />
                ) : (
                  <Button>
                    <PlusOutlined />
                  </Button>
                )}
              </Upload>
            </ImgCrop>
          ) : (
            <div className="flex items-center justify-start">
              <div className="w-1/4">
                <ImgCrop
                  rotate
                  aspect={4 / 3}
                  modalOk="Save"
                  beforeCrop={beforeCrop}
                  modalClassName="imageCrop"
                >
                  <Upload
                    showUploadList={false}
                    name="imageUrl"
                    listType="picture-card"
                    beforeUpload={beforeUploadImage}
                    disabled={true}
                  >
                    {generatedImage ? (
                      <div className="flex items-center justify-center w-[100px] h-[100px] ">
                        <Spin size="small" />
                      </div>
                    ) : itemImage ? (
                      <img
                        src={itemImage}
                        alt="avatar"
                        className="h-[100px] w-[100px] object-cover bg-gray-200 border-2 border-white rounded-lg"
                      />
                    ) : (
                      <div>
                        <img
                          src={
                            newItemImage
                              ? newItemImage
                              : itemImage
                              ? itemImage
                              : props?.data?.imageUrl
                          }
                          alt="avatar"
                          className="h-[100px] w-[100px] object-cover bg-gray-200 border-2 border-white rounded-lg "
                        />
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </div>
              <div className="flex flex-col justify-between gap-2 w-3/4">
                <TextArea
                  placeholder="Item name"
                  value={itemName || form.getFieldValue("name")}
                  allowClear
                  onChange={(e) => setItemName(e.target.value)}
                />
                <div className="flex items-center justify-start">
                  <Button
                    className="w-[90%]"
                    onClick={() =>
                      generateImage(form.getFieldValue("name"), itemName)
                    }
                    disabled={imageGenerationCount === 0}
                  >
                    Generate Image
                  </Button>
                  <Badge
                    className="site-badge-count-109 ml-3"
                    count={imageGenerationCount}
                    style={{ backgroundColor: "#3B82F6" }}
                  />
                </div>
              </div>
            </div>
          )}
        </Form.Item>
        <Form.Item label="Price :" name="isFixed" style={{ marginBottom: 10 }}>
          <Radio.Group options={plainOptions} onChange={handleIsFixedChange} />
        </Form.Item>
        <Form.Item
          name="price"
          rules={[
            {
              required: true,
              message: "Amount is Required!",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            type={"number"}
            placeholder="Base price"
            min={0}
          />
        </Form.Item>
        {!isFixed && (
          <Space className="flex items-start justify-between h-[40px]">
            <Form.Item
              name="modifierGroups"
              label="Modifier Groups :"
              rules={[
                {
                  required: true,
                  message: "Modifier Group is required",
                },
              ]}
            ></Form.Item>
            <Form.Item>
              <Link to={"/modifiers"} className="text-blue-600">
                + Add New Modifier
              </Link>
            </Form.Item>
          </Space>
        )}
        {!isFixed && renderRows()}

        {!isFixed && (
          <Form.Item>
            <Link className="text-blue-600" onClick={handleAddRow}>
              + Add Modifier
            </Link>
          </Form.Item>
        )}
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end mt-1">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-40 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditItemModal;
