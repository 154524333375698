export async function generateImageAI(description, userId, imageName) {
  let res = "";
  try {
    const body = {
      description: description,
      user_id: userId,
      img_name: imageName,
    };
    const token = "Bearer " + localStorage.getItem("authToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
        Authorization: token,
      },
      body: JSON.stringify(body),
    };
    await fetch(
      `${process.env.REACT_APP_IMAGE_GENERATION_API_URL}generate_image`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error("Error generating image: ", error);
        res = error;
      });
  } catch (error) {
    console.error(error);
    res = error;
  }
  return res;
}
