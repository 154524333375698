import { Descriptions, Modal, Tag } from "antd";
import React from "react";
import { STATUS } from "../../helpers/constants";

function ItemInfoModal(props) {
  return (
    <Modal
      title="Item info"
      open={props.visible}
      onCancel={props.handleCancel}
      footer={null}
      destroyOnClose={true}
      className="text-black font-semibold"
    >
      <Descriptions
        column={3}
        contentStyle={{ marginRight: "30px" }}
        layout="vertical"
        labelStyle={{ color: "gray", fontWeight: "400" }}
      >
        <Descriptions.Item label="Item">{props.data?.name}</Descriptions.Item>
        <Descriptions.Item label="Category">
          {props.data?.category?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Position">
          {props.data?.position}
        </Descriptions.Item>
        <Descriptions.Item label="Image">
          {props.data?.imageUrl && (
            <img
              src={props.data.imageUrl}
              alt="avatar"
              className="w-full max-h-[100px] object-fill p-0 rounded-lg"
            />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Description" span={2}>
          {props.data?.description}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {props.data?.status === STATUS.Available ? (
            <Tag color="green">Available</Tag>
          ) : props.data?.status === STATUS.Hidden ? (
            <Tag color="red">Hide</Tag>
          ) : (
            <Tag color="orange">Out of stock</Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Base Price" span={2}>
          {props.data?.price}
        </Descriptions.Item>
        <Descriptions.Item label="Modifier Groups">
          {props.data?.modifierGroups &&
          props.data?.modifierGroups.length > 0 ? (
            props.data?.modifierGroups.map((value, index) => (
              <Descriptions.Item key={value.id}>
                {`${index + 1} : `}
                {value.name}
                {value.modifiers?.length > 0 && (
                  <>
                    {" ("}
                    {value.modifiers
                      .map((modifier) => modifier.name)
                      .join(", ")}
                    {")"}
                  </>
                )}
                <br />
              </Descriptions.Item>
            ))
          ) : (
            <span>No Modifier Groups available</span>
          )}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}

export default ItemInfoModal;
