import { Button, Form, Input, Modal, Spin } from "antd";
import React, { useState } from "react";
import EditCategoryNameApi from "../../../api/Categories/EditCategoryNameApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../../helpers/Notifications";
import { LoadingOutlined } from "@ant-design/icons";

function EditCategoryModal(props) {
  const [submit, setSubmit] = useState(false);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  async function onFinish(value) {
    setSubmit(true);
    const apiResponse = await EditCategoryNameApi(props.data?.id, value.name);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess(
        "bottomRight",
        "Category name successfully changed"
      );
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't edit category name. Try again later."
      );
    }
    handleCancel();
  }

  return (
    <Modal
      title="Edit category"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="editCategory"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        initialValues={{ name: props.data?.name }}
      >
        <Form.Item
          label="Category name:"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter category name!",
            },
          ]}
        >
          <Input placeholder="Category name" />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-28 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditCategoryModal;
