export const STATUS_VALUE = {
  1: { status: 'Active', color: 'bg-blue-100', borderColor: 'border-blue-600', textColor: 'text-blue-700' },
  2: { status: 'Available', color: 'bg-green-100', borderColor: 'border-green-600', textColor: 'text-green-700' },
  3: { status: 'Disabled', color: 'bg-gray-300', borderColor: 'border-gray-700', textColor: 'text-gray-700' },
  4: { status: 'Deleted', color: 'bg-red-100', borderColor: 'border-red-700', textColor: 'text-red-700' },
  5: { status: 'OutOfStock', color: 'bg-yellow-100', borderColor: 'border-yellow-700', textColor: 'text-yellow-700' },
  6: { status: 'Selected', color: 'bg-indigo-100', borderColor: 'border-indigo-700', textColor: 'text-indigo-700' },
  7: { status: 'Confirmed', color: 'bg-green-200', borderColor: 'border-green-700', textColor: 'text-green-700' },
  8: { status: 'Completed', color: 'bg-green-400', borderColor: 'border-green-800', textColor: 'text-green-700' },
  9: { status: 'Accepted', color: 'bg-blue-200', borderColor: 'border-blue-600', textColor: 'text-blue-700' },
  10: { status: 'Rejected', color: 'bg-red-200', borderColor: 'border-red-600', textColor: 'text-red-700' },
  11: { status: 'Cancelled', color: 'bg-red-200', borderColor: 'border-red-500', textColor: 'text-red-700' },
  12: { status: 'Archived', color: 'bg-gray-400', borderColor: 'border-gray-900', textColor: 'text-gray-700' },
  13: { status: 'Closed', color: 'bg-gray-600', borderColor: 'border-gray-900', textColor: 'text-gray-700' },
  14: { status: 'Requested', color: 'bg-purple-200', borderColor: 'border-purple-800', textColor: 'text-purple-700' },
  15: { status: 'Enabled', color: 'bg-green-300', borderColor: 'border-green-900', textColor: 'text-green-700' },
  16: {status: 'Hidden', color: 'bg-gray-100', borderColor: 'border-gray-600', textColor: 'text-gray-700'},
  17: {status: 'Occupied', color: 'bg-yellow-100', borderColor: 'border-yellow-600', textColor: 'text-yellow-700'},
    18: {status: 'Free', color: 'bg-blur-100', borderColor: 'border-blue-600', textColor: 'text-blue-700'},
};
