import { Button, Form, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import ChangeCategoryPositionApi from "../../api/Categories/ChangeCategoryPositionApi";
const { Option } = Select;

function ChangeCategoryPositionModal(props) {
  const [submit, setSubmit] = useState(false);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  async function onFinish(value) {
    setSubmit(true);

    const apiResponse = await ChangeCategoryPositionApi(
      props.data?.id,
      value.position
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess(
        "bottomRight",
        "Category position successfully changed"
      );
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't change category position. Try again later."
      );
    }
    handleCancel();
  }

  return (
    <Modal
      title="Change position"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="changeCategoryPosition"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        initialValues={{ position: props.data?.position }}
      >
        <Form.Item label="Position:" name="position">
          <Select
            allowClear
            showSearch
            placeholder="Select category"
            optionFilterProp="children"
            rules={[
              {
                required: true,
                message: "Please select category!",
              },
            ]}
          >
            {Array.from(
              { length: props.totalCountData?.totalCount }, // Use totalCount directly
              (_, i) => i + 1 // Start index from 1 to match the category count
            )?.map((categ) => {
              return (
                <Option key={categ} value={categ}>
                  {categ}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={props.handleCancel}>
              Cancel
            </Button>
            <Button
              className="w-28 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Change"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangeCategoryPositionModal;
