import React from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import OrderAnalytics from "../../components/widgets/OrderAnalytics";
import PopularItems from "../../components/widgets/PopularItems";
import CompletedVsActive from "../../components/widgets/CompletedVsActive";
import ItemAvailability from "../../components/widgets/ItemAvailability";

function Dashboard() {
  const left = <div className="pri-font text-lg">Dashboard</div>;
  return (
    <div className="my-4">
      <DashboardBreadcrumb left={left} />
      <div className=" grid grid-cols-1 md:grid-cols-3 gap-5 mb-5">
        <CompletedVsActive />
        <ItemAvailability />
        <PopularItems />
      </div>
      <OrderAnalytics />
    </div>
  );
}

export default Dashboard;
