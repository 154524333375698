import { Button, Form, Modal, Select, Spin } from "antd";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import ChangeItemStatusApi from "../../api/Items/ChangeItemStatusApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import { STATUS } from "../../helpers/constants";
const { Option } = Select;

function ChangeItemStatusModal(props) {
  const [submit, setSubmit] = useState(false);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  async function onFinish(value) {
    setSubmit(true);
    const apiResponse = await ChangeItemStatusApi(props.data?.id, value.status);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess(
        "bottomRight",
        "Item status successfully changed"
      );
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't change item status. Try again later."
      );
    }
    handleCancel();
  }

  return (
    <Modal
      title="Change status"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="changeItemStatus"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        initialValues={{ status: props.data?.status }}
      >
        <Form.Item label="Status:" name="status">
          <Select placeholder="Select status">
            <Option value={STATUS.Available}>Available</Option>
            <Option value={STATUS.Hidden}>Hide</Option>
            <Option value={STATUS.OutOfStock}>Out of stock</Option>
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={props.handleCancel}>
              Cancel
            </Button>
            <Button
              className="w-28 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Change"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangeItemStatusModal;
