import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Modal, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GetWorkspaceMoreInfoApi from "../../api/Workspace/GetWorkspaceMoreinfoApi";
import io from "socket.io-client";
import { STATUS } from "../../helpers/constants";

function WorkspaceItemInfoModal(props) {
  const curLocation = useLocation();
  const [customerData, setCustomerData] = useState([]);
  const socket = io(`${process.env.REACT_APP_API_URL}`);
  const path = curLocation.pathname;
  const id = path.split("/");


  useEffect(() => {
    setCustomerData(props?.data);
  }, [props?.data]);

  const sendAcceptOrder = () => {
    socket?.emit("acceptOrderItem", acceptOrderDetail);
  };

  const sendRejectOrder = () => {
    socket?.emit("rejectOrderItem", rejectOrderDetail);
  };

  const acceptOrderDetail = {
    orderId: id[2],
    menuItemId: props.data?.menuItemId,
  };

  const rejectOrderDetail = {
    orderId: id[2],
    menuItemId: props.data?.menuItemId,
  };


  return (
    <>
      <Modal
        title="Item info"
        open={props.visible}
        onCancel={props.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <div className="flex justify-between items-center ">
          <div className="flex flex-col gap-2">
            <div className="font-semibold flex items-center">
              <span className="font-bold text-base">Item: </span>
              <div className="ml-2">{props.data?.menuName}</div>
              <div className="ml-5 font-normal">
                {props.data?.status === STATUS.Confirmed ? (
                  <Tag color="yellow">Confirmed</Tag>
                ) : props.data?.status === STATUS.Accepted ? (
                  <Tag color="green">Accepted</Tag>
                ) : (
                  <Tag color="red">Rejected</Tag>
                )}
              </div>
              <div className=" flex gap-3 items-center ml-14">
                {(props.data?.status === STATUS.Confirmed ||
                  props.data?.status === STATUS.Rejected) && (
                  <Button
                    size="small"
                    style={{ borderColor: "green" }}
                    onClick={() => {
                      sendAcceptOrder();
                      props.handleCancel();
                    }}
                    icon={<CheckCircleOutlined style={{ color: "green" }} />}
                    disabled={props.status}
                  />
                )}
                {(props.data?.status === STATUS.Confirmed ||
                  props.data?.status === STATUS.Accepted) && (
                  <Button
                    size="small"
                    style={{ borderColor: "red" }}
                    onClick={() => {
                      sendRejectOrder();
                      props.handleCancel();
                    }}
                    icon={<CloseCircleOutlined style={{ color: "red" }} />}
                    disabled={props.status}
                  />
                )}
              </div>
            </div>
            <div className="font-semibold flex items-center">
              <span className="font-bold text-base">Quantity: </span>
              <div className="ml-2">{props.data?.noOfItems}</div>
            </div>
          </div>
        </div>
        <div>
          <div className="font-bold mt-2 text-base">Order by:</div>
          <div className="ml-5">
            {customerData?.orderBy?.map((order, index) => (
              <div key={index}>
                <div className="text-base font-semibold mb-1">{order.name}</div>
                {order.modifier_order_items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="text-xs text-gray-400 leading-5"
                  >
                    <div>
                      {item.name} :{" "}
                      {item.modifier.map((modifier, modifierIndex) => (
                        <span key={modifierIndex}>
                          {modifier.name}
                          {modifierIndex !== item.modifier.length - 1 && ", "}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="text-base font-semibold mb-3 mt-1">
                  {order.note !== "" && (
                    <span className="font-bold">Note : </span>
                  )}
                  {order.note !== "" && order.note}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
}

export default WorkspaceItemInfoModal;
