export default async function AddItemApi(
  name,
  description,
  position,
  categoryId,
  imageUrl,
  price,
  isFixed,
  addedModifierGroups
) {
  let res = "";
  try {
    const body = {
      name: name,
      description: description,
      position: position,
      categoryId: categoryId,
      imageUrl: imageUrl,
      isFixed: isFixed,
      price: price,
      modifierGroups: addedModifierGroups.map((modifier) => ({
        id: modifier.id,
        name: modifier.name,
        position: modifier.position,
      })),
    };
    const token = "Bearer " + localStorage.getItem("authToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    };
    await fetch(`${process.env.REACT_APP_API_URL}v1/menu-item`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error("Error add menu-item: ", error);
        res = error;
      });
  } catch (error) {
    console.error(error);
    res = error;
  }

  return res;
}
