import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import GetQrApi from "../../../api/Qr/GetQrApi";
import AddTableApi from "../../../api/Tables/AddTableApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../../helpers/Notifications";

function AddTableModal(props) {
  const [submit, setSubmit] = useState(false);
  const [data, setData] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    if (props.visible) {
      getQrCodes();
    }
  }, [props.visible]);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  async function onFinish(values) {
    setSubmit(true);
    const apiResponse = await AddTableApi(
      values.name,
      values.seats,
      values.qrId || null
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", apiResponse.message);
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't add item try again later."
      );
    }
    handleCancel();
  }

  async function getQrCodes() {
    const apiResponse = await GetQrApi();
    if (apiResponse.success) {
      setData(apiResponse.data);
    }
  }

  return (
    <Modal
      title="Add new table"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="addTable"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Name :"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter Table name!",
            },
          ]}
        >
          <Input placeholder="Table name" />
        </Form.Item>
        <Form.Item
          label="No of seats :"
          name="seats"
          rules={[
            {
              required: true,
              message: "Please enter number of seats!",
            },
          ]}
        >
          <Input placeholder="No of seats" />
        </Form.Item>
        <Form.Item label="QR Code :" name="qrId">
          <Select
            allowClear
            showSearch
            placeholder="Select qr"
            rules={[
              {
                required: true,
                message: "Please select qr!",
              },
            ]}
          >
            {data?.map((qr) => {
              return (
                <Option key={qr.id} value={qr.id}>
                  {qr.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-40 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Add"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddTableModal;
