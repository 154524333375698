import { Button, Form, Input, Modal, Select, Slider, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { GetCategoryApi } from "../../../api/Categories/GetCategoryApi";
import { STATUS } from "../../../helpers/constants";
import { useSelector } from "react-redux";
const { Option } = Select;

function ItemsFilterModal(props) {
  const [submit, setSubmit] = useState(false);
  const [category, setCategory] = useState();
  const [inputSize, setInputSize] = useState([200, 400]);
  const businessState = useSelector((state) => state.businessState.value);

  useEffect(() => {
    getCategory();
  }, []);

  const onChangeSize = (newValue) => {
    props.priceRange(newValue);
    setInputSize(newValue);
  };

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
    setInputSize([200, 400]);
  }

  const onFinish = (values) => {
    setSubmit(true);
    props.handleOk(values);
    handleCancel();
  };

  async function getCategory() {
    const apiResponse = await GetCategoryApi();
    if (apiResponse.success) {
      setCategory(apiResponse.data);
    }
  }

  return (
    <Modal
      title="Filter Items"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="itemsFilter"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item label="Name" name="name">
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item label="Category :" name="category">
          <Select
            allowClear
            showSearch
            placeholder="Select category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
              optionA.children
                .toLowerCase()
                .localeCompare(optionB.children.toLowerCase())
            }
            rules={[
              {
                required: true,
                message: "Please select category!",
              },
            ]}
          >
            {category?.map((categ) => {
              return (
                <Option key={categ.name} value={categ.name}>
                  {categ.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label="Price range :" name="priceRange">
          <div className="grid grid-cols-3 gap-5 ">
            <Slider
              className="col-span-2"
              range
              min={0}
              max={businessState.maxPrice}
              step={10}
              defaultValue={[200, 400]}
              onAfterChange={onChangeSize}
            />

            <Input
              style={{ pointerEvents: "none" }}
              value={`${businessState.currency?.symbol} ${inputSize[0]} - ${inputSize[1]}`}
            />
          </div>
        </Form.Item>
        <Form.Item label="Status :" name="status">
          <Select placeholder="Select status">
            <Option value={STATUS.Available}>Available</Option>
            <Option value={STATUS.Disabled}>Disabled</Option>
            <Option value={STATUS.OutOfStock}>Out of stock</Option>
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={props.handleCancel}>
              Cancel
            </Button>
            <Button
              className="w-28 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Filter"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ItemsFilterModal;
