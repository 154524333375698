import React, { useEffect, useState } from "react";
import { Drawer, Layout } from "antd";
import DashboardSideMenu from "../components/layout/DashboardSideMenu";
import { Link, Outlet } from "react-router-dom";
import DashboardHeader from "../components/layout/DashboardHeader";
import { Content } from "antd/es/layout/layout";
import { useSelector } from "react-redux";
const { Sider } = Layout;

function DashboardLayout(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const proWidth = document.body.clientWidth;
  const businessState = useSelector((state) => state.businessState.value);

  useEffect(() => {
    /*In tab view collapse menu when loaded*/
    if (proWidth < 720) {
      setCollapsed(true);
    }
  }, []);

  return (
    <Layout
      hasSider
      style={{
        minHeight: "100vh",
      }}
    >
      {/*Main side menu*/}
      <Sider
        collapsible
        trigger={null}
        theme="light"
        collapsed={collapsed}
        breakpoint="lg"
        style={{
          overflow: "auto",
          height: "100vh",
          zIndex: 99,
        }}
        className="transparent-scroll hidden sm:block "
      >
        <div>
          {businessState?.logo ? (
            <img
              className="p-2 h-16 w-64 object-cover object-center"
              src={businessState?.logo}
              alt={"LOGO"}
            />
          ) : (
            <Link to="/home" className={"flex items-center  gap-2 h-16 pl-7"}>
              <div className="text-2xl font-bold">{businessState?.name}</div>
            </Link>
          )}
        </div>
        <DashboardSideMenu
          createData={props.createData}
          confirmData={props.confirmData}
          addCustomerData={props.addCustomerData}
          acceptData={props.acceptData}
          rejectData={props.rejectData}
          completeData={props.completeData}
          enableCustomerAdminData={props.enableCustomerAdminData}
          enableCustomerByAnotherData={props.enableCustomerByAnotherData}
          disableCustomerAdminData={props.disableCustomerAdminData}
          disableCustomerByAnotherData={props.disableCustomerByAnotherData}
          soundEnabled={soundEnabled} 
        />
      </Sider>

      {/*Mobile side menu*/}
      <Drawer
        maskClosable={true}
        width="280px"
        closable={false}
        placement="left"
        onClose={() => setCollapsed(true)}
        open={!collapsed}
        className="sm:hidden "
        style={{ padding: 0 }}
      >
        <DashboardSideMenu soundEnabled={soundEnabled} />
      </Drawer>

      <Layout
        className="site-layout custom-scroll  "
        style={{
          transition: "all .2s",
          overflow: "auto",
          height: "100vh",
        }}
      >
        <DashboardHeader
          openMenu={() => setCollapsed(!collapsed)}
          menu={collapsed}
          soundEnabled={soundEnabled}
          setSoundEnabled={setSoundEnabled}
        />
        <Content className=" pb-2 overflow-y-auto custom-scroll ">
          <div className="mx-4">
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default DashboardLayout;
