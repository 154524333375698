import {ControlOutlined, LockOutlined, ProfileOutlined, ShopOutlined} from "@ant-design/icons";
import { Menu } from "antd";
import { DownOutlined, SmileOutlined ,UserOutlined} from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const subMenu = {

  0: "User Profile",
  1: "Business Profile",
  2: "Preferences",
  3: "Change password",
  4: "Users",

};
const items = [
  getItem(<Link to="/settings/user-profile">User Profile</Link>, "0", <ProfileOutlined />),
  getItem(<Link to="/settings/business-profile">Business Profile</Link>, "1", <ShopOutlined />),
  getItem(
    <Link to="/settings/preferences">Preferences</Link>,
    "2",
    <ControlOutlined />
  ),
  getItem(
    <Link to="/settings/change-password">Change password</Link>,
    "3",
    <LockOutlined />
  ),
  getItem(<Link to="/settings/users">Users</Link>, "4", <UserOutlined />),

];

const paths = [
  { path: "/settings/user-profile", key: "0" },
  { path: "/settings/business-profile", key: "1" },
  { path: "/settings/preferences", key: "2" },
  { path: "/settings/change-password", key: "3" },
  { path: "/settings/users", key: "4" },

];

function Settings() {
  const proHeight = document.body.clientHeight;
  const [mainKey, setMainKey] = useState("");
  let curLocation = useLocation();

  useEffect(() => {
    setMainKey("");
    paths.map((path) => {
      if (path.path === curLocation.pathname) {
        setMainKey(path.key);
      }
    });
  }, [curLocation]);

  const left = (
    <>
      <div className="pri-font text-lg">Settings</div>
    </>
  );

  return (
    <>
      {/* <DashboardBreadcrumb left={left} /> */}
      <div  className="my-4">
      <Content style={{ backgroundColor: "#FC33AB4" }}>
        <div className="flex flex-col md:flex-row overflow-auto">
          <div className="w-full md:w-64 border-r p-3 md:p-0 custom-scroll">
            <Menu
              className={"hidden md:block h-full"}
              mode="inline"
              selectedKeys={[mainKey]}
              items={items}
              style={{ border: "none" }}
            />
            <Dropdown
              className={"md:hidden w-auto bg-white p-3 rounded "}
              menu={{ items }}
            >
              <Space
                className={
                  "md:hidden px-4 flex justify-between text-lg font-semibold"
                }
              >
                {subMenu[mainKey]}
                <DownOutlined className={"text-xl"} />
              </Space>
            </Dropdown>
          </div>
          <div className="w-full  bg-gray-100">
            <Outlet />
          </div>
        </div>
        </Content>
        </div>
    </>
  );
}

export default Settings;
