import { Button, Modal } from "antd";
import React from "react";

function ConfirmationModal(props) {

  function handleCancel() {
    props.handleCancel();
  }

  return (
    <Modal
      title={props.title}
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
    >
      <div className="mb-6">{props.text}</div>

      <div className=" flex justify-end">
        <Button className="mr-3 w-20" onClick={() => handleCancel()}>
          No
        </Button>
        <Button
          className="w-20 bg-blue-500"
          type="primary"
          htmlType="submit"
          onClick={props.handleOk}
        >
          yes
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
