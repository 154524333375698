export default async function EditModifierGroupApi(
  id,
  name,
  isRequired,
  minimum,
  maximum,
  modifiers,
  deleteModifiers
) {
  let res = "";
  try {
    const body = {
      id: id,
      name: name,
      isRequired: isRequired,
      minimum: minimum,
      maximum: maximum,
      editModifiers: modifiers.map((modifier) => ({
        modifierId:modifier.id,
        name: modifier.name,
        isPreSelected: modifier.isPreSelected,
        price: modifier.price,
      })),
      deleteModifiers: deleteModifiers
    };
    const token = "Bearer " + localStorage.getItem("authToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    };
    await fetch(`${process.env.REACT_APP_API_URL}v1/modifier`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error("Error editing modifier: ", error);
        res = error;
      });
  } catch (error) {
    console.error(error);
    res = error;
  }

  return res;
}
