import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Slider,
  Spin,
  DatePicker,
} from "antd";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { STATUS } from "../../../helpers/constants";

const { Option } = Select;
const { RangePicker } = DatePicker;

function OrdersFilterModal(props) {
  const [submit, setSubmit] = useState(false);
  const [inputSize, setInputSize] = useState([500, 2000]);

  const onChangeSize = (newValue) => {
    props.priceRange(newValue);
    setInputSize(newValue);
  };

  const onChangeDate = (newValue) => {
    const startDate = newValue[0].toISOString();
    const endDate = newValue[1].toISOString();
    props.dateRange([startDate, endDate]);
  };

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
    setInputSize([500, 2000]);
  }

  const onFinish = (values) => {
    setSubmit(true);
    props.handleOk(values);
    handleCancel();
  };

  return (
    <Modal
      title="Filter Orders"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="categoriesFilter"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item label="Table No :" name="tableNo">
          <Select placeholder="Table No">
            <Option value="01">01</Option>
            <Option value="02">02</Option>
            <Option value="03">03</Option>
            <Option value="04">04</Option>
            <Option value="05">05</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Date range" name="dateRange">
          <RangePicker className="w-full" onChange={onChangeDate} />
        </Form.Item>
        <Form.Item label="Price range :" name="priceRange">
          <div className="grid grid-cols-3 gap-5 ">
            <Slider
              className="col-span-2"
              range
              min={0}
              max={10000}
              step={10}
              defaultValue={[500, 2000]}
              onAfterChange={onChangeSize}
            />

            <Input
              style={{ pointerEvents: "none" }}
              value={`$ ${inputSize[0]} - ${inputSize[1]}`}
            />
          </div>
        </Form.Item>
        <Form.Item label="Status :" name="status">
          <Select placeholder="Select status">
            <Option value={STATUS.Completed}>Completed</Option>
            <Option value={STATUS.Cancelled}>Cancelled</Option>
          </Select>
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={props.handleCancel}>
              Cancel
            </Button>
            <Button
              className="w-28"
              type="primary"
              htmlType={submit && "submit"}
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Filter"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default OrdersFilterModal;
