import { Descriptions, Modal, Tag } from "antd";
import React from "react";

function ModifierInfoModal(props) {
  return (
    <Modal
      title="Modifier group info"
      open={props.visible}
      onCancel={props.handleCancel}
      footer={null}
      destroyOnClose={true}
      className="text-black font-semibold"
      
    >
      <Descriptions column={2} layout="vertical" labelStyle={{color:'gray' , fontWeight:'400'}}>
        <Descriptions.Item label="Modifier Group Name:">
          {props.data?.name}  
        </Descriptions.Item>
        <Descriptions.Item label="Modifier Group Type:">
          {props.data?.type}
        </Descriptions.Item>
        <Descriptions.Item label="Minimum:">
          {props.data?.minimum}
        </Descriptions.Item>
        <Descriptions.Item label="Maximum:">
          {props.data?.maximum}
        </Descriptions.Item>
        <Descriptions.Item>
          <Descriptions title="Modifiers" labelStyle={{color:'gray' , fontWeight:'400'}} layout="vertical">
            {props.data?.modifier.map((value) => (
              <Descriptions.Item
                key={value.id}
                label={[value.name, value.isPreSelected && <span key="preselect"><Tag color="red" className="text-[10px] p-[1px] py-0">Preselect</Tag></span>]}
              >
                {value.price}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}

export default ModifierInfoModal;
