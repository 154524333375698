export default async function AddCategoryApi(
    name,
    description,
    position,
  ) {
    let res = "";
    try {
      const body = {
        name: name,
        description: description,
        position: position,
      };
      const token = "Bearer " + localStorage.getItem("authToken");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: token,
        },
        body: JSON.stringify(body),
      };
      await fetch(`${process.env.REACT_APP_API_URL}v1/category`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          res = response;
        })
        .catch((error) => {
          console.error("Error add category: ", error);
          res = error;
        });
    } catch (error) {
      console.error(error);
      res = error;
    }
  
    return res;
  }
  