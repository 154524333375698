import { Empty, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { GetDashboardPopularItemsApi } from "../../api/Dashboard/GetDashboardPopularItemsApi";

function PopularItems() {
  const [data, setData] = useState();

  useEffect(() => {
    getPopularItems();
  }, []);

  async function getPopularItems() {
    const apiResponse = await GetDashboardPopularItemsApi();
    if (apiResponse.success) {
      setData(apiResponse.data);
    }
  }

  function getItems() {
    let output = [];

    data?.map((item) => {
      output.push(
        <div key={item.menuId} className="flex justify-between mb-2">
          <div>
            <div className="truncate">{item.menuName}</div>
            <div className="text-xs text-gray-500">{item.categoryName}</div>
          </div>
          <div>{item.noOfItems}</div>
        </div>
      );
    });
    return output;
  }
  return (
    <div className="bg-white p-5 rounded-md ">
      <div className="flex justify-between items-center mb-2">
        <div className="text-lg mb-2 font-semibold text-gray-600">
          Popular items
        </div>
        <div className="text-gray-400">Today</div>
      </div>
      {data ? (
        <div className="text-gray-600">{getItems()}</div>
      ) : (
        <Empty description={<span>No popular items available</span>} />
      )}
    </div>
  );
}

export default PopularItems;
