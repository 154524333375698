import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { GetMeApi } from "../api/User/GetMeApi";
import { login, logout } from "../features/AuthSlice";
import { userDetails } from "../features/UserSlice";
import DashboardLayout from "../layouts/DashboardLayout";
import WebLayout from "../layouts/WebLayout";
import MainLoader from "../Loader/MainLoader";
import Categories from "../views/dashboard/Categories";
import Dashboard from "../views/dashboard/Dashboard";
import Employees from "../views/dashboard/Employees";
import Items from "../views/dashboard/Items";
import QRCode from "../views/dashboard/QRCodes";
import Orders from "../views/dashboard/Orders";
import Workspace from "../views/dashboard/Workspace";
import WorkspaceItem from "../views/dashboard/WorkspaceItem";
import Login from "../views/web/Login";
import ProtectedRoute from "./ProtectedRoute";
import Tables from "../views/dashboard/Tables";
import OrderedItem from "../views/dashboard/OrderedItem";
import InitialRoute from "./InitialRoute";
import ForgotPassword from "../views/web/ForgotPassword";
import ResetPassword from "../views/web/ResetPassword";
import BusinessProfile from "../views/dashboard/BusinessProfile";
import Settings from "../views/Settings/Settings";
import ChangePassword from "../views/dashboard/ChangePassword";
import io from "socket.io-client";
import Preferences from "../views/dashboard/Preferences";
import GetBusinessApi from "../api/Business/GetBusinessApi";
import { businessDetails } from "../features/BusinessSlice";
import Users from "../views/dashboard/Users";
import UserProfile from "../views/dashboard/UserProfile";
import ModifierGroup from "../views/dashboard/ModifierGroups";

function Main() {
  const authState = useSelector((state) => state.authState.value);
  const [confirmOrderdata, setConfirmOrderData] = useState([]);
  const [acceptOrderData, setAcceptOrderData] = useState([]);
  const [rejectOrderData, setRejectOrderData] = useState([]);
  const [completeOrderData, setCompleteOrderData] = useState([]);
  const [cancelOrderData, setCancelOrderData] = useState();
  const [createOrderData, setCreateOrderData] = useState();
  const [enableCustomerAdminData, setEnableCustomerAdminData] = useState();
  const [disableCustomerAdminData, setDisableCustomerAdminData] = useState();
  const [enableCustomerByAnotherData, setEnableCustomerByAnotherData] =
    useState();
  const [disableCustomerByAnotherData, setDisableCustomerByAnotherData] =
    useState();
  const [addCustomerData, setAddCustomerData] = useState();
  const [business, setBusiness] = useState(false);
  const dispatch = useDispatch();
  const socket = io(`${process.env.REACT_APP_API_URL}`);

  useEffect(() => {
    getUserDetails();
    getBusiness();
  }, [authState, business]);

  const confirmOrder = (data) => {
    setConfirmOrderData(data?.data);
  };

  const acceptOrderItem = (data) => {
    setAcceptOrderData(data?.data);
  };

  const rejectOrderItem = (data) => {
    setRejectOrderData(data?.data);
  };

  const completeOrder = (data) => {
    setCompleteOrderData(data?.data);
  };

  const cancelOrder = (data) => {
    setCancelOrderData(data?.data);
  };

  const createOrder = (data) => {
    setCreateOrderData(data?.data);
    setAcceptOrderData([]);
    setCompleteOrderData([]);
    setConfirmOrderData([]);
    setRejectOrderData([]);
  };

  const enableCustomerAdmin = (data) => {
    setEnableCustomerAdminData(data?.data);
  };

  const disableCustomerAdmin = (data) => {
    setDisableCustomerAdminData(data?.data);
  };

  const enableCustomerByAother = (data) => {
    setEnableCustomerByAnotherData(data?.data);
  };

  const disableCustomerByAother = (data) => {
    setDisableCustomerByAnotherData(data?.data);
  };

  const addCustomerName = (data) => {
    setAddCustomerData(data?.data);
  };

  useEffect(() => {
    socket?.on("create-order-with-customer", createOrder);
    socket?.on("confirmOrder", confirmOrder);
    socket?.on("acceptOrderItem", acceptOrderItem);
    socket?.on("rejectOrderItem", rejectOrderItem);
    socket?.on("completeOrder", completeOrder);
    socket?.on("cancelOrder", cancelOrder);
    socket?.on("enable-customer-admin", enableCustomerAdmin);
    socket?.on("disable-customer-admin", disableCustomerAdmin);
    socket?.on("enable-customer-by-another", enableCustomerByAother);
    socket?.on("disable-customer-by-another", disableCustomerByAother);
    socket?.on("add-customer-name", addCustomerName);

    return () => {
      socket?.off("create-order-with-customer", createOrder);
      socket?.off("confirmOrder", confirmOrder);
      socket?.off("acceptOrderItem", acceptOrderItem);
      socket?.off("rejectOrderItem", rejectOrderItem);
      socket?.off("completeOrder", completeOrder);
      socket?.off("cancelOrder", cancelOrder);
      socket?.off("enable-customer-admin", enableCustomerAdmin);
      socket?.off("disable-customer-admin", disableCustomerAdmin);
      socket?.off("enable-customer-by-another", enableCustomerByAother);
      socket?.off("disable-customer-by-another", disableCustomerByAother);
      socket?.off("add-customer-name", addCustomerName);
    };
  }, []);

  async function getUserDetails() {
    const apiResponse = await GetMeApi();
    if (apiResponse.statusCode) {
      localStorage.setItem("authToken", "");
      localStorage.setItem("authUser", "");
      dispatch(logout());
    } else if (apiResponse.username) {
      dispatch(login());
      dispatch(userDetails(apiResponse));
      setBusiness(true);
    } else {
      localStorage.setItem("authToken", "");
      localStorage.setItem("authUser", "");
      dispatch(logout());
    }
  }

  async function getBusiness() {
    const apiResponse = await GetBusinessApi();
    if (apiResponse.success) {
      dispatch(businessDetails(apiResponse?.data));
    }
  }

  return authState ? (
    <Routes>
      <Route element={<InitialRoute />}>
        <Route element={<WebLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password/:token" element={<ResetPassword />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path="/*"
          element={
            <DashboardLayout
              createData={createOrderData}
              confirmData={confirmOrderdata}
              addCustomerData={addCustomerData}
              acceptData={acceptOrderData}
              rejectData={rejectOrderData}
              completeData={completeOrderData}
              enableCustomerAdminData={enableCustomerAdminData}
              enableCustomerByAnotherData={enableCustomerByAnotherData}
              disableCustomerAdminData={disableCustomerAdminData}
              disableCustomerByAnotherData={disableCustomerByAnotherData}
            />
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route
            path="workspace"
            element={
              <Workspace
                createData={createOrderData}
                confirmData={confirmOrderdata}
                addCustomerData={addCustomerData} 
              />
            }
          />
          <Route
            path="workspace/:id"
            element={
              <WorkspaceItem
                confirmData={confirmOrderdata}
                acceptData={acceptOrderData}
                rejectData={rejectOrderData}
                completeData={completeOrderData}
                cancelData={cancelOrderData}
                enableCustomerAdmin={enableCustomerAdminData}
                disableCustomerAdmin={disableCustomerAdminData}
                enableCustomerByAnother={enableCustomerByAnotherData}
                disableCustomerByAnother={disableCustomerByAnotherData}
                addCustomerData={addCustomerData}
              />
            }
          />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:id" element={<OrderedItem />} />
          <Route path="categories" element={<Categories />} />
          <Route path="items" element={<Items />} />
          <Route path="modifiers" element={<ModifierGroup />} />
          <Route path="tables" element={<Tables />} />
          <Route path="qr-codes" element={<QRCode />} />
          <Route path="settings/*" element={<Settings />}>
            <Route path="user-profile" element={<UserProfile />} />
            <Route path="business-profile" element={<BusinessProfile />} />
            <Route path="preferences" element={<Preferences />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route path="users" element={<Users />} />
            <Route
              path="*"
              element={<Navigate replace to="/settings/user-profile" />}
            />
          </Route>
          {/* <Route path="services" element={<Services />} /> */}
          <Route path="employees" element={<Employees />} />

          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Route>
      </Route>
    </Routes>
  ) : (
    <div className="mt-36">
      <MainLoader />
    </div>
  );
}

export default Main;
