import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Modal, Tag, Button, Table } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import { STATUS } from "../../helpers/constants";
import io from "socket.io-client";

function CustomersAccessModal(props) {
  const curLocation = useLocation();
  const socket = io(`${process.env.REACT_APP_API_URL}`);

  const path = curLocation.pathname;
  const id = path.split("/");

  const enableCustomer = (customerId) => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  const sendEnableCustomerAdmin = (customerId) => {
    const enableCustomerDetail = {
      orderId: id[2],
      customerId: customerId,
    };
    socket?.emit("enable-customer-admin", enableCustomerDetail);
  };

  const disableCustomer = (customerId) => {
    return new Promise((resolve, reject) => {
      resolve();
    });
  };

  const sendDisableCustomerAdmin = (customerId) => {
    const disableCustomerDetail = {
      orderId: id[2],
      customerId: customerId,
    };
    socket?.emit("disable-customer-admin", disableCustomerDetail);
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <>
          {status === STATUS.Requested ? (
            <Tag color={"orange"}>Requested</Tag>
          ) : status === STATUS.Enabled ? (
            <Tag color="green">Enabled</Tag>
          ) : (
            <Tag color="red">Disabled</Tag>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <div className="flex gap-2">
          {record.status !== STATUS.Enabled && (
            <Button
              style={{ borderColor: "green" }}
              size="small"
              icon={<CheckCircleOutlined style={{ color: "green" }} />}
              onClick={() => {
                enableCustomer(record.customerId).then(() => {
                  sendEnableCustomerAdmin(record.customerId);
                });
              }}
            />
          )}
          {record.status !== STATUS.Disabled &&
            props.data?.filter(
              (customer) =>
                customer.status === STATUS.Requested ||
                customer.status === STATUS.Enabled
            ).length !== 1 && (
              <Button
                danger
                size="small"
                icon={<CloseCircleOutlined className="text-red-400" />}
                onClick={() => {
                  disableCustomer(record.customerId).then(() => {
                    sendDisableCustomerAdmin(record.customerId);
                  });
                }}
              />
            )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Users"
        visible={props.visible}
        onCancel={props.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Table
          dataSource={props.data}
          columns={columns}
          rowKey="customerId"
          pagination={false}
        />
      </Modal>
    </>
  );
}

export default CustomersAccessModal;
