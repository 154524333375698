export default async function RemoveUserProfileApi() {
    let res = "";
    try {
      const token = "Bearer " + localStorage.getItem("authToken");
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: token,
        },
        
      };
      await fetch(
        `${process.env.REACT_APP_API_URL}v1/user/remove-user-profile`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          res = response;
        })
        .catch((error) => {
          console.error("Error delete item: ", error);
          res = error;
        });
    } catch (error) {
      console.error(error);
      res = error;
    }

    return res;
  }