import {
  Button,
  Table,
  Tag,
  Tooltip,
  theme,
  Space,
  Pagination,
  Badge,
  Popover,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  FilterOutlined,
  PlusOutlined,
  WarningOutlined,
  IssuesCloseOutlined,
  ColumnHeightOutlined,
  UndoOutlined,
  CheckCircleFilled,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import AddCategoryModal from "../../components/modals/add/AddCategoryModal";
import CategoriesFilterModal from "../../components/modals/filters/CategoriesFilterModal";
import EditCategoryModal from "../../components/modals/edit/EditCategoryModal";
import ChangeCategoryStatusModal from "../../components/modals/ChangeCategoryStatusModal";
import ChangeCategoryPositionModal from "../../components/modals/ChangeCategoryPositionModal";
import GetCategoryTableApi from "../../api/Categories/GetCategoryTableApi";
import MainLoader from "../../Loader/MainLoader";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import DeleteCategoryApi from "../../api/Categories/DeleteCategoryApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import GetFilteredCategoriesTableApi from "../../api/Categories/GetFilteredCategoriesTableApi";
import { STATUS } from "../../helpers/constants";
import DataCard from "../../components/widgets/DataCard";
const { useToken } = theme;

function Categories() {
  const { token } = useToken();
  const [canShowTable, setCanShowTable] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [positionModal, setPositionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState();
  const [canStart, setCanStart] = useState(false);
  const [page, setPage] = useState(1);
  const [changePosition, setChangePosition] = useState();
  const [changeStatus, setChangeStatus] = useState();
  const [filterStatus, setFilterStatus] = useState(false);
  const [filterBy, setFilterBy] = useState();
  const [selectCategory, setSelectCategory] = useState();

  const buttons = (category) => (
    <Space className="bg-blue-50 px-1.5 py-1 rounded" size="middle">
      <Tooltip title={"Change status"}>
        <div
          onClick={() => {
            setStatusModal(true);
            setChangeStatus(category);
          }}
          className="text-blue-500 hover:text-blue-600 cursor-pointer"
        >
          <IssuesCloseOutlined />
        </div>
      </Tooltip>
      <Tooltip title={"Change position"}>
        <div
          onClick={() => {
            setPositionModal(true);
            setChangePosition(category);
          }}
          className="text-blue-500 hover:text-blue-600 cursor-pointer"
        >
          <ColumnHeightOutlined />
        </div>
      </Tooltip>
      {!category?.menuItemsCount ? (
        <Tooltip title={"Delete"}>
          <div
            onClick={() => {
              setSelectCategory(category);
              setDeleteModal(true);
            }}
            className="text-red-500 hover:text-red-600 cursor-pointer"
          >
            <DeleteOutlined />
          </div>
        </Tooltip>
      ) : (
        <Popover
          placement="left"
          content="Category has associated items, can't delete."
          trigger="hover"
        >
          <DeleteOutlined className="text-gray-500" />
        </Popover>
      )}
    </Space>
  );

  useEffect(() => {
    getCategories(1);
  }, []);

  async function getCategories(num) {
    setTableLoading(true);
    const apiResponse = await GetCategoryTableApi(num);
    if (apiResponse.success) {
      if (apiResponse.data?.categories?.length) {
        setCanShowTable(true);
      }
      setData(apiResponse.data);
      setCanStart(true);
      setTableLoading(false);
      setPage(num);
    }
  }

  async function deleteCategory() {
    const apiResponse = await DeleteCategoryApi(selectCategory.id);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Category deleted");
      if (data?.categories?.length.toString().endsWith("1")) {
        changeTablePage(page - 1);
      } else {
        changeTablePage(page);
      }
    } else {
      openNotificationError("bottomRight", "Couldn't delete. Try again later.");
    }
    setSelectCategory("");
  }

  async function getFilteredCategories(values, num) {
    setTableLoading(true);
    const apiResponse = await GetFilteredCategoriesTableApi(
      values.name || "",
      values.status || "",
      num
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
      setTableLoading(false);
      setFilterStatus(true);
      setFilterBy({ name: values.name, status: values.status });
      setPage(num);
    }
  }

  function changeTablePage(num) {
    if (filterStatus) {
      getFilteredCategories(filterBy, num);
    } else {
      getCategories(num);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="flex gap-2">
          <div>{record.name}</div>

          <Button
            type="primary"
            ghost
            style={{ border: "none" }}
            size="small"
            onClick={() => {
              setSelectCategory(record);
              setEditModal(true);
            }}
            icon={<EditOutlined />}
          />
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Items count",
      dataIndex: "menuItemsCount",
      key: "menuItemsCount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          {record.status === STATUS.Available ? (
            <Tag color="green">Available</Tag>
          ) : record.status === STATUS.Hidden ? (
            <Tag color="red">Hide</Tag>
          ) : (
            <Tag color="orange">Out of stock</Tag>
          )}
        </Space>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title={"Change status"}>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => {
                setStatusModal(true);
                setChangeStatus(record);
              }}
              icon={<IssuesCloseOutlined />}
            />
          </Tooltip>
          <Tooltip title={"Change position"}>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => {
                setPositionModal(true);
                setChangePosition(record);
              }}
              icon={<ColumnHeightOutlined />}
            />
          </Tooltip>

          {!record?.menuItemsCount ? (
            <Tooltip title={"Delete"}>
              <Button
                danger
                size="small"
                onClick={() => {
                  setSelectCategory(record);
                  setDeleteModal(true);
                }}
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          ) : (
            <Popover
              placement="left"
              content="Category has associated items, can't delete."
              trigger="hover"
            >
              <Button
                style={{ borderColor: "gray" }}
                size="small"
                icon={<DeleteOutlined className="text-gray-500" />}
              />
            </Popover>
          )}
        </Space>
      ),
    },
  ];

  const actions = {};

  function showEmptyCategories() {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="text-center">
          <div className="text-3xl text-gray-400">
            <WarningOutlined />
          </div>
          <div className="text-xl pri-font text-gray-400">
            No categories available
          </div>
          <div className="mb-3 text-gray-400">Add your first category</div>
          <Button
            type="primary"
            style={{ background: token.colorPrimary }}
            onClick={() => setAddModal(true)}
          >
            Add a category
          </Button>
        </div>
      </div>
    );
  }

  function resetFilter() {
    getCategories(1);
    setFilterStatus(false);
    setFilterBy();
  }

  const right = (
    <>
      {canShowTable && (
        <>
          <Tooltip title={"Reset"}>
            <Button
              disabled={!filterStatus}
              onClick={() => resetFilter()}
              className="mr-3"
            >
              <UndoOutlined />
            </Button>
          </Tooltip>
          <Tooltip title={"Filter"}>
            <Badge
              count={
                filterStatus ? (
                  <CheckCircleFilled style={{ color: "#f5222d" }} />
                ) : (
                  0
                )
              }
              offset={[-14, 2]}
            >
              <Button className="mr-3" onClick={() => setFilterModal(true)}>
                <FilterOutlined />
              </Button>
            </Badge>
          </Tooltip>
          <Tooltip placement="left" title={"Add categories"}>
            <Button
              style={{ background: token.colorPrimary }}
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddModal(true)}
            ></Button>
          </Tooltip>
        </>
      )}
    </>
  );

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Categories</div>
    </>
  );

  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} right={right} />
        <div>
          {canStart ? (
            canShowTable ? (
              <>
                <div>
                  <Table
                    className="hidden md:block"
                    dataSource={data?.categories}
                    columns={columns}
                    loading={tableLoading}
                    pagination={false}
                  />
                </div>

                <div className={"md:hidden grid grid-cols-1 gap-2"}>
                  {data?.categories.map((category) => (
                    <DataCard
                      key={category?.id}
                      position={category?.position}
                      title={category?.name}
                      status={category?.status}
                      description={category?.description}
                      count={category?.menuItemsCount}
                      availability={category?.status}
                      actions={buttons(category)}
                    />
                  ))}
                </div>

                {data?.totalCount > 10 && (
                  <div className="text-end mt-4">
                    <Pagination
                      onChange={(num) => {
                        changeTablePage(num);
                        setPage(num);
                      }}
                      current={page}
                      total={data?.totalCount || 0}
                      pageSize={10}
                    />
                  </div>
                )}
              </>
            ) : (
              showEmptyCategories()
            )
          ) : (
            <MainLoader />
          )}
        </div>
        <AddCategoryModal
          visible={addModal}
          data={data}
          handleCancel={() => setAddModal(false)}
          handleOk={() => getCategories(1)}
        />
        <CategoriesFilterModal
          visible={filterModal}
          handleCancel={() => setFilterModal(false)}
          handleOk={(values) => getFilteredCategories(values, 1)}
        />
        <EditCategoryModal
          data={selectCategory}
          visible={editModal}
          handleOk={() => getCategories(1)}
          handleCancel={() => setEditModal(false)}
        />

        <ChangeCategoryStatusModal
          data={changeStatus}
          visible={statusModal}
          handleOk={() => getCategories(1)}
          handleCancel={() => setStatusModal(false)}
        />
        <ChangeCategoryPositionModal
          data={changePosition}
          totalCountData={data}
          visible={positionModal}
          handleOk={() => getCategories(1)}
          handleCancel={() => setPositionModal(false)}
        />
        <ConfirmationModal
          visible={deleteModal}
          handleCancel={() => {
            setDeleteModal(false);
            setSelectCategory("");
          }}
          handleOk={() => {
            deleteCategory();
            setDeleteModal(false);
          }}
          text="Are you sure want to delete this category?"
          title="Delete categories"
        />
      </div>
    </>
  );
}

export default Categories;
