import React, { useEffect, useState } from "react";
import { Popover, QRCode, Space, Tooltip, Button } from "antd";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  StopOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import ChangeTableModal from "../../components/modals/ChangeTableModal";
import GetQrCardsApi from "../../api/Qr/GetQrCardsApi";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import DisableQrApi from "../../api/Qr/DisableQrApi";
import EnableQrApi from "../../api/Qr/EnableQrApi";
import { STATUS } from "../../helpers/constants";
import RemoveQrTableApi from "../../api/Qr/RemoveQrTableApi";
import { useSelector } from "react-redux";

function QRCodes() {
  const [changeTableModal, setChangeTableModal] = useState(false);
  const [data, setData] = useState();
  const [qrData, setQrData] = useState();
  const [disableModal, setDisableModal] = useState(false);
  const [disableId, setDisableId] = useState();
  const [enableModal, setEnableModal] = useState(false);
  const [enableId, setEnableId] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [removeId, setRemoveId] = useState();

  const businessState = useSelector((state) => state.businessState.value);

  useEffect(() => {
    getQrCodes();
  }, []);

  async function getQrCodes() {
    const apiResponse = await GetQrCardsApi();
    if (apiResponse.success) {
      setData(apiResponse.data);
    }
  }

  async function disableQr() {
    const apiResponse = await DisableQrApi(disableId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Qr was disabled successfully");
      getQrCodes();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't disable. Try again later."
      );
    }
    setDisableId("");
  }

  async function enableQr() {
    const apiResponse = await EnableQrApi(enableId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Qr was enabled successfully");
      getQrCodes();
    } else {
      openNotificationError("bottomRight", "Couldn't enable. Try again later.");
    }
    setEnableId("");
  }

  async function removeTable() {
    const apiResponse = await RemoveQrTableApi(removeId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess(
        "bottomRight",
        "Qr's table was removed successfully"
      );
      getQrCodes();
    } else {
      openNotificationError("bottomRight", "Couldn't remove. Try again later.");
    }
    setRemoveId("");
  }

  function getQrDetails() {
    let output = [];
    data?.map((qrs) => {
      output.push(
        <div key={qrs.id} className="bg-white p-5 rounded-md">
          <div className="flex items-center justify-between mb-3">
            <div className="text-base text-gray-600 font-semibold">
              {qrs.status === STATUS.Active ? (
                qrs.table?.name ? (
                  <>
                    <div className="flex gap-2">
                      <div>{qrs?.table?.name}</div>
                      <Tooltip title={"Remove table"}>
                        <Button
                          danger
                          size="small"
                          onClick={() => {
                            setRemoveModal(true);
                            setRemoveId(qrs.id);
                          }}
                          icon={<DeleteOutlined />}
                        />
                      </Tooltip>
                      <Tooltip>
                        <Button
                          type="primary"
                          ghost
                          size="small"
                          onClick={() => {
                            setChangeTableModal(true);
                            setQrData(qrs);
                          }}
                          icon={<SwapOutlined />}
                        />
                      </Tooltip>
                    </div>
                  </>
                ) : (
                  <Button
                    type="primary"
                    className="cursor-pointer"
                    ghost
                    size="medium"
                    onClick={() => {
                      setChangeTableModal(true);
                      setQrData(qrs);
                    }}
                  >
                    <PlusCircleOutlined className="text-[20px]" />
                    Assign Table
                  </Button>
                )
              ) : (
                <div className="text-orange-500">Disabled..</div>
              )}
            </div>{" "}
            <Space size="middle">
              {qrs.status === STATUS.Active ? (
                <>
                  {!qrs.table?.id ? (
                    <Tooltip title={"Disable"}>
                      <Button
                        style={{ borderColor: "orange" }}
                        onClick={() => {
                          setDisableModal(true);
                          setDisableId(qrs.id);
                        }}
                        size="small"
                        icon={<StopOutlined className="text-orange-500" />}
                      />
                    </Tooltip>
                  ) : (
                    <Popover
                      placement="left"
                      content="Can't disable, this qr assigned with table"
                      trigger="hover"
                    >
                      <Button
                        style={{ borderColor: "gray" }}
                        size="small"
                        icon={<StopOutlined className="text-gray-500" />}
                      />
                    </Popover>
                  )}

                  <Tooltip title={"Download"}>
                    <Button
                      style={{ borderColor: "green" }}
                      onClick={() => downloadQRCode()}
                      size="small"
                      icon={<DownloadOutlined className="text-green-500" />}
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip title={"Enable"}>
                  <Button
                    style={{ borderColor: "green" }}
                    onClick={() => {
                      setEnableModal(true);
                      setEnableId(qrs.id);
                    }}
                    size="small"
                    icon={<DownloadOutlined className="text-green-500" />}
                  />
                </Tooltip>
              )}
            </Space>
          </div>
          <div className="myqrcode">
            <QRCode
              className="mx-auto"
              value={`${process.env.REACT_APP_BASE_URL}/${businessState?.id}/${qrs?.id}`}
            />
          </div>
          <div className="text-center mt-3 text-gray-600"> {qrs?.id}</div>
        </div>
      );
    });
    return output;
  }

  const downloadQRCode = () => {
    const canvas = document.querySelector(".myqrcode")?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const left = (
    <>
      <div className="pri-font text-lg">QR Codes</div>
    </>
  );
  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
          {getQrDetails()}
        </div>
        <ChangeTableModal
          visible={changeTableModal}
          data={qrData}
          handleCancel={() => setChangeTableModal(false)}
          handleOk={() => {
            getQrCodes();
            setChangeTableModal(false);
          }}
        />
        <ConfirmationModal
          visible={disableModal}
          handleCancel={() => setDisableModal(false)}
          handleOk={() => {
            disableQr();
            setDisableModal(false);
          }}
          text="Are you sure want to disable this qr?"
          title="Disable qr"
        />
        <ConfirmationModal
          visible={enableModal}
          handleCancel={() => setEnableModal(false)}
          handleOk={() => {
            enableQr();
            setEnableModal(false);
          }}
          text="Are you sure want to enable this qr?"
          title="Enable qr"
        />
        <ConfirmationModal
          visible={removeModal}
          handleCancel={() => setRemoveModal(false)}
          handleOk={() => {
            removeTable();
            setRemoveModal(false);
          }}
          text="Are you sure want to remove this table from qr?"
          title="Remove table"
        />
      </div>
    </>
  );
}

export default QRCodes;
