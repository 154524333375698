import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Upload,
  Icon,
  message,
  Radio,
  Space,
  Row,
  Col,
  Tooltip,
  Badge,
} from "antd";
import ImgCrop from "antd-img-crop";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { GetCategoryApi } from "../../../api/Categories/GetCategoryApi";
import AddItemApi from "../../../api/Items/AddItemApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../../helpers/Notifications";
import { UploadImagesApi } from "../../../api/UploadImagesApi";
import { Link } from "react-router-dom";
import GetModifierGroupByNameApi from "../../../api/modifierGroups/SearchModifierGroupsApi";
import { generateImageAI } from "../../../api/GenerateImageApi";
import { useSelector } from "react-redux";
const { TextArea } = Input;
const { Option } = Select;

function AddItemModal(props) {
  const [submit, setSubmit] = useState(false);
  const [category, setCategory] = useState();
  const [menuItemsCount, setMenuItemsCount] = useState();
  const [form] = Form.useForm();
  const [itemImage, setItemImage] = useState("");
  const [isFixed, setIsFixed] = useState(true);
  const [modifierRows, setModifierRows] = useState([{}]);
  const [modifierGroups, setModifierGroups] = useState([{}]);
  const [imageOption, setImageOption] = useState("Upload");
  const [itemName, setItemName] = useState("");
  const [generatedImage, setGeneratedImage] = useState(false);
  const businessState = useSelector((state) => state.businessState.value);
  const [imageGenerationCount, setImageGenerationCount] = useState(3);

  const plainOptions = ["Fixed", "Variant"];

  useEffect(() => {
    if (props.visible) {
      getCategory();
    }
  }, [props.visible]);

  function handleCancel() {
    setSubmit(false);
    selectCategory();
    props.handleCancel();
    form.resetFields();
    setItemImage("");
    setItemName("");
    setIsFixed(true);
    setImageOption("Upload");
    setModifierRows([{}]);
    setImageGenerationCount(3);
    setGeneratedImage(false);
  }

  useEffect(() => {
    fetchModifierGroups();
  }, []);

  const fetchModifierGroups = async () => {
    try {
      const apiResponse = await GetModifierGroupByNameApi("");
      setModifierGroups(apiResponse.data);
    } catch (error) {
      console.error("Error fetching modifier groups: ", error);
    }
  };

  async function getCategory() {
    const apiResponse = await GetCategoryApi();
    if (apiResponse.success) {
      setCategory(apiResponse.data);
    }
  }

  async function onFinish(values) {
    const isFixed = values.isFixed === "Fixed";
    let addedModifierGroups = [];
    if (!isFixed) {
      addedModifierGroups = values.modifierGroups;
    }
    setSubmit(true);
    const apiResponse = await AddItemApi(
      values.name,
      values.description,
      values.position,
      values.categoryId,
      itemImage ? itemImage : values.imageUrl,
      values.price,
      isFixed,
      addedModifierGroups
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", apiResponse.message);
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't add item try again later."
      );
    }
    handleCancel();
    setItemImage("");
  }

  function selectCategory(value) {
    category.map((categ) => {
      if (categ.id === value) {
        setMenuItemsCount(categ.menuItemsCount);
        form.setFields([
          {
            name: "position",
            value: categ.menuItemsCount + 1,
          },
        ]);
      }
    });
  }

  function beforeCrop(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.info("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.info("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  }

  const beforeUploadImage = async (info) => {
    await uploadImage(info, info.name);
    return false;
  };

  async function uploadImage(file, name) {
    setSubmit(true);
    const apiResponse = await UploadImagesApi(file, name, "profile");
    if (apiResponse.success) {
      setItemImage(apiResponse?.url);
    }
    setSubmit(false);
  }

  const handleAddRow = () => {
    const maxId = Math.max(...modifierRows.map((row) => row.id), 0);
    const newRow = { id: maxId + 1, selectedModifier: null };
    setModifierRows([...modifierRows, newRow]);
  };

  const handleRemoveRow = (index) => {
    setModifierRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      return updatedRows;
    });

    form.setFieldsValue({
      modifierGroups: modifierRows
        .filter((_, i) => i !== index)
        .map((row, i) => ({
          ...row,
          position: i + 1,
        })),
    });
  };

  const selectModifierGroup = (index, selectedModifier) => {
    setModifierRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index] = {
        ...updatedRows[index],
        id: selectedModifier.id,
        name: selectedModifier.name,
        position: index + 1,
      };
      return updatedRows;
    });

    form.setFieldsValue({
      modifierGroups: modifierRows.map((row, rowIndex) => {
        if (rowIndex === index) {
          return {
            id: selectedModifier.id,
            name: selectedModifier.name,
            position: index + 1,
          };
        }
        return row;
      }),
    });
  };

  const renderRows = () => {
    return modifierRows.map((value, index) => (
      <Form.Item key={index}>
        <Row gutter={16} justify="space-around" className="h-[30px]">
          <Col span={1}>
            <Form.Item
              name={["modifierGroups", index, "position"]}
              initialValue={index + 1}
            >
              {index + 1}
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name={["modifierGroups", index, "name"]}
              rules={[
                {
                  required: true,
                  message: "Modifier Group is required.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const modifierGroups = getFieldValue("modifierGroups");
                    if (
                      !modifierGroups ||
                      modifierGroups.length === 0 ||
                      !value
                    ) {
                      return Promise.resolve();
                    }
                    const otherNames = new Set(
                      modifierGroups
                        .filter((_, i) => i !== index && modifierGroups[i].name)
                        .map((row) => row.name)
                    );
                    if (otherNames.has(value)) {
                      return Promise.reject("Duplicate modifier groups");
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Select
                allowClear
                showSearch
                style={{ width: "100%" }}
                placeholder="Search"
                onSelect={(selectedId) => {
                  const selectedModifier = modifierGroups.find(
                    (modifier) => modifier.id === selectedId
                  );
                  selectModifierGroup(index, selectedModifier);
                }}
              >
                {modifierGroups.map((result) => (
                  <Option key={result.id} value={result.id}>
                    {result.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={1} className="mt-1">
            <Tooltip
              title={
                !isFixed && modifierRows.length === 1
                  ? "At least one modifier group is required for variant item"
                  : ""
              }
            >
              <Button
                size="small"
                danger
                onClick={() => handleRemoveRow(index)}
                icon={<DeleteOutlined />}
                disabled={modifierRows.length === 1 && !isFixed}
              />
            </Tooltip>
          </Col>
        </Row>
      </Form.Item>
    ));
  };

  const handleImageOptionChange = (e) => {
    setImageOption(e.target.value);
  };

  const generateImage = async (description, imageName) => {
    if (imageGenerationCount === 0) {
      return;
    }
    setGeneratedImage(true);
    setImageGenerationCount((prevCount) => prevCount - 1);
    const apiResponse = await generateImageAI(
      description,
      businessState.id,
      imageName
    );
    if (apiResponse?.Img_url) {
      setGeneratedImage(false);
      setItemImage(apiResponse?.Img_url);
    }
  };

  return (
    <Modal
      title="Add new item"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="addItem"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={true}
        form={form}
      >
        <Form.Item
          label="Name:"
          name="name"
          rules={[
            {
              required: true,
              message: "Item name is Required!",
            },
          ]}
        >
          <Input placeholder="Item name" />
        </Form.Item>
        <Form.Item
          label="Category :"
          name="categoryId"
          rules={[
            {
              required: true,
              message: "Category is Required!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select category"
            onSelect={(value) => selectCategory(value)}
          >
            {category?.map((categ) => {
              return (
                <Option key={categ.id} value={categ.id}>
                  {categ.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Position :"
          name="position"
          rules={[
            {
              required: true,
              message: "Position is Required!",
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            placeholder="Select position"
            optionFilterProp="children"
          >
            {Array.from({ length: menuItemsCount + 1 }, (_, i) => i + 1)?.map(
              (position) => {
                return (
                  <Option key={position} value={position}>
                    {position}
                  </Option>
                );
              }
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Description :" name="description">
          <TextArea placeholder="Item description" rows={3} />
        </Form.Item>
        <Form.Item
          label="Image :"
          name="imageUrl"
          rules={[
            {
              required: false,
              message: "Image is Required!",
            },
          ]}
        >
          <Radio.Group
            onChange={handleImageOptionChange}
            value={imageOption}
            style={{ marginBottom: 10 }}
          >
            <Radio value="Upload">Upload</Radio>
            <Radio value="AI">Generate using AI</Radio>
          </Radio.Group>
          {imageOption === "Upload" ? (
            <ImgCrop
              rotate
              aspect={4 / 3}
              modalOk="Save"
              beforeCrop={beforeCrop}
              modalClassName="imageCrop"
            >
              <Upload
                showUploadList={false}
                name="imageUrl"
                listType="picture-card"
                beforeUpload={beforeUploadImage}
              >
                {itemImage ? (
                  <img
                    src={itemImage}
                    alt="avatar"
                    className="h-[100px] w-[100px] object-cover bg-gray-200 border-2 border-white rounded-lg"
                  />
                ) : (
                  <Button>
                    <PlusOutlined />
                  </Button>
                )}
              </Upload>
            </ImgCrop>
          ) : (
            <div className="flex items-center justify-start">
              <div className="w-1/4">
                <ImgCrop
                  rotate
                  aspect={4 / 3}
                  modalOk="Save"
                  beforeCrop={beforeCrop}
                  modalClassName="imageCrop"
                >
                  <Upload
                    showUploadList={false}
                    name="imageUrl"
                    listType="picture-card"
                    beforeUpload={beforeUploadImage}
                    disabled={true}
                  >
                    {generatedImage ? (
                      <div className="flex items-center justify-center w-[100px] h-[100px] ">
                        <Spin size="small" />
                      </div>
                    ) : itemImage ? (
                      <img
                        src={itemImage}
                        alt="avatar"
                        className="h-[100px] w-[100px] object-cover bg-gray-200 border-2 border-white rounded-lg"
                      />
                    ) : (
                      <div>
                        <PlusOutlined />
                        <div>Generate</div>
                      </div>
                    )}
                  </Upload>
                </ImgCrop>
              </div>
              <div className="flex flex-col justify-between gap-2 w-3/4 relative">
                <TextArea
                  placeholder="Item name"
                  value={itemName || form.getFieldValue("name")}
                  allowClear
                  onChange={(e) => setItemName(e.target.value)}
                />
                <div className="flex items-center justify-start">
                  <Button
                    className="w-[90%]"
                    onClick={() =>
                      generateImage(form.getFieldValue("name"), itemName)
                    }
                    disabled={imageGenerationCount === 0}
                  >
                    Generate Image
                  </Button>
                  <Badge
                    className="site-badge-count-109 ml-3"
                    count={imageGenerationCount}
                    style={{ backgroundColor: "#3B82F6" }}
                  />
                </div>
              </div>
            </div>
          )}
        </Form.Item>
        <Form.Item
          label="Price :"
          name="isFixed"
          initialValue="Fixed"
          rules={[
            {
              required: true,
            },
          ]}
          style={{ marginBottom: 10 }}
        >
          <Radio.Group
            options={plainOptions}
            onChange={(e) => setIsFixed(e.target.value === "Fixed")}
            value={isFixed ? "Fixed" : "Variant"}
          />
        </Form.Item>
        <Form.Item
          name="price"
          rules={[
            {
              required: true,
              message: "Amount is Required!",
            },
          ]}
        >
          <InputNumber
            className="w-full"
            type={"number"}
            placeholder="Base price"
            min={0}
          />
        </Form.Item>
        {!isFixed && (
          <div className="flex items-start justify-between h-[40px]">
            <Form.Item
              name="modifierGroups"
              label="Modifier Groups :"
              rules={[
                {
                  required: true,
                  message: "Please enter Modifier Groups",
                },
              ]}
            ></Form.Item>
            <Form.Item>
              <Link to={"/modifiers"} className="text-blue-600">
                + Add New Modifier
              </Link>
            </Form.Item>
          </div>
        )}
        {!isFixed && renderRows()}
        {!isFixed && (
          <Form.Item>
            <Link className="text-blue-600" onClick={handleAddRow}>
              + Add Modifier
            </Link>
          </Form.Item>
        )}

        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end mt-1">
            <Button className="mr-3 w-28" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="w-40 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Add item"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddItemModal;
