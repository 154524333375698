import { Button, Form, Modal, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import ChangeTableQrApi from "../../api/Tables/ChangeTableQrApi";
import GetQrApi from "../../api/Qr/GetQrApi";
const { Option } = Select;

function ChangeQRModal(props) {
  const [submit, setSubmit] = useState(false);
  const [qr, setQr] = useState();

  useEffect(() => {
    if (props.visible) {
      getQrCodes();
    }
  }, [props.visible]);

  function handleCancel() {
    setSubmit(false);
    props.handleCancel();
  }

  async function getQrCodes() {
    const apiResponse = await GetQrApi();
    if (apiResponse.success) {
      setQr(apiResponse.data);
    }
  }

  async function onFinish(value) {
    setSubmit(true);
    const apiResponse = await ChangeTableQrApi(props.data?.id, value.qrId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "Table's qr successfully changed");
      props.handleOk();
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't change table's qr. Try again later."
      );
    }
    handleCancel();
  }

  return (
    <Modal
      title="Change QR"
      open={props.visible}
      onCancel={() => handleCancel()}
      footer={null}
      destroyOnClose={true}
    >
      <Form
        name="changeQR"
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item label="QR:" name="qrId" initialValue={props.data?.qrCodeNo}>
          <Select allowClear showSearch placeholder="Select QR">
            {qr?.map((qr) => {
              return (
                <Option key={qr.id} value={qr.id}>
                  {qr.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <div className=" flex justify-end">
            <Button className="mr-3 w-28" onClick={props.handleCancel}>
              Cancel
            </Button>
            <Button
              className="w-28 bg-blue-500"
              type="primary"
              htmlType="submit"
            >
              {submit ? (
                <Spin
                  size="small"
                  indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ChangeQRModal;
