import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ContainerOutlined,
  TeamOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetAllActiveOrderApi } from "../../api/Workspace/GetAllActiveOrderApi";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { STATUS } from "../../helpers/constants";
import { GetTime } from "../../helpers/Date&Time";
import io from "socket.io-client";
import { currencyFormatter } from "../../helpers/currencyFormat";
import { useSelector } from "react-redux";

function Workspace(props) {
  const [data, setData] = useState();
  const [acceptOrderModal, setAcceptOrderModal] = useState(false);
  const [requestedOrderId, setRequestedOrderId] = useState();
  const [acceptOrderData, setAcceptOrderData] = useState();
  const [rejectOrderModal, setRejectOrderModal] = useState(false);
  const [rejectOrderData, setRejectOrderData] = useState();
  const businessState = useSelector((state) => state.businessState.value);
  const socket = io(`${process.env.REACT_APP_API_URL}`);
  let navigate = useNavigate();

  useEffect(() => {
    getAllActiveOrder();
  }, [
    props.createData,
    props.confirmData,
    acceptOrderData,
    rejectOrderData,
    props.addCustomerData,
  ]);

  const sendAcceptOrder = () => {
    socket?.emit("acceptOrder", acceptOrderDetail);
  };

  const sendRejectOrder = () => {
    socket?.emit("rejectOrder", rejectOrderDetail);
  };

  const acceptOrderDetail = {
    orderId: requestedOrderId,
  };

  const rejectOrderDetail = {
    orderId: requestedOrderId,
  };

  const acceptOrder = (data) => {
    setAcceptOrderData(data);
  };

  const rejectOrder = (data) => {
    setRejectOrderData(data);
  };

  useEffect(() => {
    socket?.on("acceptOrder", acceptOrder);
    socket?.on("rejectOrder", rejectOrder);

    return () => {
      socket?.off("acceptOrder", acceptOrder);
      socket?.off("rejectOrder", rejectOrder);
    };
  }, []);

  async function getAllActiveOrder() {
    const apiResponse = await GetAllActiveOrderApi();
    if (apiResponse.success) {
      setData(apiResponse.data);
    }
  }

  function renderAllRequestOrders() {
    let output = [];
    data?.map((order) => {
      output.push(
        order.orderStatus === STATUS.Requested && (
          <div
            key={order.id}
            className="bg-white p-5 rounded-md cursor-pointer hover:shadow-md"
            onClick={() => navigate(`/workspace/${order.id}`)}
          >
            <div className="flex justify-between mb-2">
              <div className="flex items-center">
                {order?.statusFullyAccepted ? (
                  <div className="w-3 h-3 rounded-full bg-green-500 mr-1" />
                ) : (
                  <span className="relative flex h-3 w-3 mr-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                  </span>
                )}
                <div className="text-base font-semibold">{order?.qrId}</div>
              </div>
              <div className="text-base text-gray-400">{order?.tableName}</div>
            </div>
            <div className="grid grid-cols-2 gap-x-5 gap-y-2">
              <div className="flex items-center  gap-2">
                <ClockCircleOutlined
                  style={{ fontSize: "20px", color: "gray" }}
                />
                <div>{GetTime(order?.orderedOn)}</div>
              </div>
              <div className="flex items-center  gap-2">
                <TeamOutlined style={{ fontSize: "20px", color: "gray" }} />
                <div>{order?.customersCount}</div>
              </div>
            </div>
            <div className=" flex justify-end gap-4 mt-2">
              <div
                className="flex gap-2"
                onClick={(event) => {
                  event.stopPropagation();
                  setRejectOrderModal(true);
                  setRequestedOrderId(order.id);
                }}
              >
                <CloseCircleOutlined
                  className="hover:text-red-700 text-red-500"
                  style={{ fontSize: "20px" }}
                />
                <div>Reject</div>
              </div>
              <div
                className="flex gap-2"
                onClick={(event) => {
                  event.stopPropagation();
                  setAcceptOrderModal(true);
                  setRequestedOrderId(order.id);
                }}
              >
                <CheckCircleOutlined
                  className="hover:text-green-700 text-green-500"
                  style={{ fontSize: "20px" }}
                />
                <div>Accept</div>
              </div>
            </div>
          </div>
        )
      );
    });

    return output;
  }

  function renderAllActiveOrders() {
    let output = [];

    const filteredOrders = data?.filter(
      (order) =>
        order?.orderStatus === STATUS.Active ||
        order?.orderStatus === STATUS.Completed
    );

    filteredOrders?.map((order) => {
      output.push(
        <div
          key={order.id}
          className="bg-white p-5 rounded-md cursor-pointer hover:shadow-md"
          onClick={() => navigate(`/workspace/${order?.id}`)}
        >
          <div className="flex justify-between mb-2">
            <div className="flex items-center">
              {order?.statusFullyAccepted ? (
                <div className="w-3 h-3 rounded-full bg-green-500 mr-1" />
              ) : (
                <span className="relative flex h-3 w-3 mr-1">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
              )}
              <div className="text-base font-semibold">{order?.qrId}</div>
            </div>
            <div className="text-base text-gray-400">{order?.tableName}</div>
          </div>
          <div className="grid grid-cols-2 gap-x-5 gap-y-2">
            <div className="flex items-center  gap-2">
              <ClockCircleOutlined
                style={{ fontSize: "20px", color: "gray" }}
              />
              <div>{GetTime(order?.orderedOn)}</div>
            </div>
            <div className="flex items-center  gap-2">
              <TeamOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>{order?.customersCount}</div>
            </div>
            <div className="flex items-center  gap-2">
              <WalletOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>
                {currencyFormatter(
                  order?.subTotal,
                  businessState?.currency?.symbol
                )}
              </div>
            </div>
            <div className="flex items-center  gap-2">
              <ContainerOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>{order?.noOfItems}</div>
            </div>
          </div>
        </div>
      );
    });

    return output;
  }

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Workspace</div>
    </>
  );
  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 text-gray-600">
          {data && renderAllRequestOrders()}
          {data && renderAllActiveOrders()}
        </div>
        <ConfirmationModal
          visible={acceptOrderModal}
          handleCancel={() => setAcceptOrderModal(false)}
          handleOk={() => {
            sendAcceptOrder();
            setAcceptOrderModal(false);
          }}
          text="Are you sure want to accept this order?"
          title="Accept order"
        />
        <ConfirmationModal
          visible={rejectOrderModal}
          handleCancel={() => setRejectOrderModal(false)}
          handleOk={() => {
            sendRejectOrder();
            setRejectOrderModal(false);
          }}
          text="Are you sure want to reject this order?"
          title="Reject order"
        />
      </div>
    </>
  );
}

export default Workspace;
