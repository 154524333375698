import { Button, Form, Input, Upload, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import { UploadImagesApi } from "../../api/UploadImagesApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import { userDetails } from "../../features/UserSlice";
import EditUserApi from "../../api/User/EditUserApi";
import RemoveUserProfileApi from "../../api/User/RemoveUserProfileApi";

function Profile() {
  const [submit, setSubmit] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [newProfilePicture, setNewProfilePicture] = useState("");
  const [removeModal, setRemoveModal] = useState(false);
  const proHeight = document.body.clientHeight;
  const [valuesChanged, setValuesChanged] = useState(false);

  const userState = useSelector((state) => state.userState.value);
  const dispatch = useDispatch();

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">User Profile</div>
    </>
  );

  const fields = [
    {
      name: ["First Name"],
      value: userState?.firstName || "",
    },
    {
      name: ["Last Name"],
      value: userState?.firstName || "",
    },
  ];

  useEffect(() => {
    setProfilePicture(userState?.imageUrl);
  }, [userState?.imageUrl]);

  async function onFinish(values) {
    setProfilePicture(profilePicture ? profilePicture : values.imageUrl);
    setSubmit(true);
    const apiResponse = await EditUserApi(
      userState?.id,
      values.firstName,
      values.lastName,
      profilePicture ? profilePicture : values.imageUrl
    );

    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      dispatch(userDetails(apiResponse.data));
      openNotificationSuccess(
        "bottomRight",
        "User profile successfully updated"
      );
    }
    setSubmit(false);
    setValuesChanged(false);
  }

  async function uploadLogo(file, name) {
    setSubmit(true);
    const apiResponse = await UploadImagesApi(file, name, "profile");
    if (apiResponse.success) {
      setProfilePicture(apiResponse?.url);
    }
    setSubmit(false);
  }

  function beforeCrop(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.info("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.info("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUploadLogo = async (info) => {
    await uploadLogo(info, userState.id);
    getBase64(info, (photo) => {
      setNewProfilePicture(photo);
    });
    return false;
  };

  async function removeUserProfile() {
    const apiResponse = await RemoveUserProfileApi();
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      dispatch(userDetails(apiResponse.data));
      setProfilePicture("");
      setNewProfilePicture("");
      openNotificationSuccess("bottomRight", "User Profile Removed");
    } else {
      openNotificationError("bottomRight", "Couldn't remove. Try again later.");
    }
  }

  return (
    <>
      <div className="ml-4">
        <DashboardBreadcrumb left={left} />
        <div
          className="bg-white p-4"
          style={{
            height: proHeight - 122,
          }}
        >
          <Form
            name="userProfile"
            onValuesChange={() => {
              setValuesChanged(true);
            }}
            fields={fields}
            onFinish={onFinish}
            layout="vertical"
            requiredMark={false}
            initialValues={{
              firstName: userState?.firstName,
              lastName: userState?.lastName,
            }}
            className="grid mt-2 gap-4 "
          >
            <div className="flex items-center justify-left relative">
              <div className="w-36 h-36 rounded-full  flex flex-col items-center justify-center">
                <Form.Item name="profile">
                  <ImgCrop
                    rotate
                    aspect={4 / 4}
                    modalOk="Save"
                    beforeCrop={beforeCrop}
                    modalClassName="imageCrop"
                  >
                    <Upload
                      name="imageUrl"
                      listType="picture-circle"
                      showUploadList={false}
                      beforeUpload={beforeUploadLogo}
                    >
                      {newProfilePicture ||
                      profilePicture ||
                      userState?.imageUrl ? (
                        <div className="relative mt-7 h-full w-full overflow-hidden rounded-full cursor-auto">
                          <img
                            src={
                              newProfilePicture
                                ? newProfilePicture
                                : profilePicture
                                ? profilePicture
                                : userState?.imageUrl
                            }
                            alt="avatar"
                            className="rounded-full bg-gray-200 border-4 border-white "
                          />
                          <div className="absolute top-0 left-0 w-full h-full  hover:bg-gray-900 hover:bg-opacity-50 transition-opacity duration-300">
                            <div className="h-full w-full flex items-center justify-center opacity-0 hover:opacity-100  transition-opacity duration-300">
                              <EditOutlined className="text-red-400 text-2xl cursor-pointer" />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center max-h-32 h-32 w-32 rounded-full bg-gray-200 border-4 mt-6 border-white">
                          <span className="text-center"> + Upload</span>
                        </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </div>
              {newProfilePicture || profilePicture || userState?.imageUrl ? (
                <div className="absolute mt-24 ml-28">
                  <Button
                    danger
                    size="small"
                    icon={<DeleteOutlined />}
                    onClick={() => setRemoveModal(true)}
                  />
                </div>
              ) : null}
            </div>

            <div className="max-w-xl">
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your First name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your First name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Button
                className="w-40 bg-blue-500"
                type="primary"
                htmlType="submit"
                //  disabled={valuesChanged}
              >
                {submit ? (
                  <Spin
                    size="small"
                    indicator={
                      <LoadingOutlined style={{ color: "#fff" }} spin />
                    }
                  />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <ConfirmationModal
        visible={removeModal}
        handleCancel={() => setRemoveModal(false)}
        handleOk={() => {
          removeUserProfile();
          setRemoveModal(false);
        }}
        text="Are you sure want to Remove User Profile?"
        title="Remove User Profile"
      />
    </>
  );
}

export default Profile;
