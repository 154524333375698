import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Pagination, Space, Table, Tag, theme, Tooltip } from "antd";
import { GetUsersTableApi } from "../../api/User/GetUsersTableApi";
import {
  WarningOutlined,
  StopOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { STATUS } from "../../helpers/constants";
import MainLoader from "../../Loader/MainLoader";
import { Content } from "antd/es/layout/layout";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import DisableUserApi from "../../api/User/DisableUserApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import EnableUserApi from "../../api/User/EnableUserApi";
import RemoveUserApi from "../../api/User/RemoveUserApi";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import AddUserModal from "../../components/modals/add/AddUserModal";
import DataCard from "../../components/widgets/DataCard";

const { useToken } = theme;

function Users() {
  const { token } = useToken();
  const [canShowTable, setCanShowTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState();
  const [canStart, setCanStart] = useState(false);
  const [page, setPage] = useState(1);
  const businessState = useSelector((state) => state.businessState.value);
  const [disableId, setDisableId] = useState("");
  const [enableId, setEnableId] = useState("");
  const [removeId, setRemoveId] = useState("");
  const [disableModal, setDisableModal] = useState(false);
  const [enableModal, setEnableModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  useEffect(() => {
    if (businessState.id) {
      getUsers(1);
    }
  }, [businessState?.id, disableId, enableId, removeId]);

  async function disableUser() {
    const apiResponse = await DisableUserApi(disableId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "User was disabled successfully");
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't disable. Try again later."
      );
    }
    setDisableId("");
  }

  async function enableUser() {
    const apiResponse = await EnableUserApi(enableId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "User was enabled successfully");
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't disable. Try again later."
      );
    }
    setEnableId("");
  }

  async function removeUser() {
    const apiResponse = await RemoveUserApi(removeId);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      openNotificationSuccess("bottomRight", "User was removed successfully");
    } else {
      openNotificationError("bottomRight", "Couldn't remove. Try again later.");
    }
    setRemoveId("");
  }

  async function getUsers(num) {
    setTableLoading(true);
    const response = await GetUsersTableApi(num);
    if (response.success) {
      if (response.data?.users?.length) {
        setCanShowTable(true);
      }
      setData(response.data);
      setCanStart(true);
      setTableLoading(false);
      setPage(num);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <div className="flex gap-2">
          <div>{record.firstName}</div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          {text === STATUS.Disabled ? (
            <Tag color="red">Disabled</Tag>
          ) : text === STATUS.Active ? (
            <Tag color="green"> Active </Tag>
          ) : (
            <Tag color="yellow">Removed</Tag>
          )}
        </Space>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          {record.status === 3 ? (
            <Tooltip title={"Enable User"}>
              <Button
                type="primary"
                ghost
                size="small"
                onClick={() => {
                  setEnableId(record.id);
                  setEnableModal(true);
                }}
                icon={<CheckCircleOutlined />}
              />
            </Tooltip>
          ) : (
            <Tooltip title={"Disable User"}>
              <Button
                style={{ borderColor: "orange" }}
                onClick={() => {
                  setDisableId(record.id);
                  setDisableModal(true);
                }}
                size="small"
                icon={<StopOutlined className="text-orange-500" />}
              />
            </Tooltip>
          )}

          <Tooltip title={"Delete User"}>
            <Button
              danger
              onClick={() => {
                setRemoveId(record.id);
                setRemoveModal(true);
              }}
              size="small"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const buttons = (record) => (
    <Space size="middle">
      {record.status === 3 ? (
        <Tooltip title={"Enable User"}>
          <div
            onClick={() => {
              setEnableId(record.id);
              setEnableModal(true);
            }}
            className="text-blue-500 hover:text-blue-600 cursor-pointer"
          >
            <StopOutlined className={"text-blue-700"} />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={"Disable User"}>
          <div
            onClick={() => {
              setDisableId(record.id);
              setDisableModal(true);
            }}
            className="text-blue-500 hover:text-blue-600 cursor-pointer"
          >
            <StopOutlined className={"text-red-500"} />
          </div>
        </Tooltip>
      )}

      <Tooltip title={"Delete User"}>
        <div
          onClick={() => {
            setRemoveId(record.id);
            setRemoveModal(true);
          }}
          className="text-blue-500 hover:text-blue-600 cursor-pointer"
        >
          <DeleteOutlined />
        </div>
      </Tooltip>
    </Space>
  );

  function showEmptyUsers() {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="text-center">
          <div className="text-3xl text-gray-400">
            <WarningOutlined />
          </div>
          <div className="text-xl pri-font text-gray-400">
            No Users available
          </div>
        </div>
      </div>
    );
  }

  function changeTablePage(num) {
    getUsers(num);
  }

  const right = (
    <>
      {canShowTable && (
        <>
          <Tooltip placement="left" title={"Add User"}>
            <Button
              style={{ background: token.colorPrimary }}
              shape="circle"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAddModal(true)}
            ></Button>
          </Tooltip>
        </>
      )}
    </>
  );

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Users</div>
    </>
  );

  return (
    <>
      <div className="ml-4">
        <DashboardBreadcrumb left={left} right={right} />
        <Content className="bg-white p-4 h-screen"
        >
          <div>
            {canStart ? (
              canShowTable ? (
                <>
                  <Table
                    className="hidden md:block"
                    dataSource={data?.users}
                    columns={columns}
                    loading={tableLoading}
                    pagination={false}
                    rowClassName=" cursor-pointer"
                  />

                  <div className={"md:hidden grid grid-cols-1 gap-2"}>
                    {data?.users.map((user) => (
                      <DataCard
                        key={user?.id}
                        category={user?.role}
                        title={user?.firstName}
                        actions={buttons(user)}
                        status={user?.status}
                        email={user?.email}
                      />
                    ))}
                  </div>

                  {data?.totalCount > 10 && (
                <div className="text-end mt-4">
                  <Pagination
                    onChange={(num) => {
                      changeTablePage(num);
                      setPage(num);
                    }}
                    current={page}
                    total={data?.totalCount || 0}
                    pageSize={10}
                  />
                </div>
)}
                </>
              ) : (
                showEmptyUsers()
              )
            ) : (
              <MainLoader />
            )}
          </div>
        </Content>
      </div>
      <AddUserModal
        visible={addModal}
        data={data}
        handleCancel={() => setAddModal(false)}
        handleOk={() => getUsers(1)}
      />
      <ConfirmationModal
        visible={disableModal}
        handleCancel={() => setDisableModal(false)}
        handleOk={() => {
          disableUser();
          setDisableModal(false);
        }}
        text="Are you sure want to disable this User?"
        title="Disable User"
      />
      <ConfirmationModal
        visible={enableModal}
        handleCancel={() => setEnableModal(false)}
        handleOk={() => {
          enableUser();
          setEnableModal(false);
        }}
        text="Are you sure want to enable this User?"
        title="Enable User"
      />
      <ConfirmationModal
        visible={removeModal}
        handleCancel={() => setRemoveModal(false)}
        handleOk={() => {
          removeUser();
          setRemoveModal(false);
        }}
        text="Are you sure want to remove this User?"
        title="Remove User"
      />
    </>
  );
}

export default Users;
