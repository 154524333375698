import React, { useState } from "react";
import { Form, Input, Button, Card, Spin, theme } from "antd";
import { LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ForgotPasswordApi from "../../api/User/ForgotPasswordApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";

const { useToken } = theme;

function ForgotPassword() {
  const [submit, setSubmit] = useState(false);
  const [email, setEmail] = useState();
  const { token } = useToken();
  const [form] = Form.useForm();

  async function onFinish() {
    setSubmit(true);

    const apiResponse = await ForgotPasswordApi(email);
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else {
      form.resetFields();
      openNotificationSuccess(
        "bottomRight",
        `Reset link sent to ${email}. Check Your inbox`
      );
    }
    setSubmit(false);
  }

  return (
    <>
      <div className="h-screen flex w-full  items-center justify-center">
        <div className=" w-full max-w-lg p-3">
          <Card
            style={{
              borderColor: "#e5e7eb",
            }}
          >
            <div>
              <div className="text-2xl pri-font text-center mb-2">
                Forgot your password?
              </div>
              <div className="text-gray-600 mb-5">
                {" "}
                Please enter the email address you'd like your password reset
                information sent to
              </div>
            </div>
            <Form
              name="forgotPassword"
              form={form}
              onFinish={onFinish}
              className="w-full max-w-lg"
              initialValues={{ remember: true }}
              layout={"vertical"}
              requiredMark={false}
            >
              <Form.Item
                label={<div className="text-gray-600">Enter email address</div>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter an email!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <MailOutlined style={{ color: token.colorPrimary }} />
                  }
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>

              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType={submit && "submit"}
                  className="w-full"
                >
                  {submit ? (
                    <Spin
                      size="small"
                      indicator={
                        <LoadingOutlined style={{ color: "#fff" }} spin />
                      }
                    />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form.Item>
            </Form>
            <div className="text-center mt-5 mb-4">
              Remember password?{" "}
              <Link to="/login" href="">
                Login
              </Link>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
