import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import GetOrderItemMoreInfoApi from "../../api/Orders/GetOrderItemMoreInfoApi";

function OrderedItemInfoModal(props) {
  const [data, setData] = useState();
  const [customerData, setCustomerData] = useState([]);
  const curLocation = useLocation();

  useEffect(() => {
    if (props.visible) {
      getMoreInfo();
    }
  }, [props.visible]);

  useEffect(() => {
    setCustomerData(props?.data);
  }, [props?.data]);

  async function getMoreInfo() {
    const path = curLocation.pathname;
    const id = path.split("/");

    const apiResponse = await GetOrderItemMoreInfoApi(
      id[2],
      props.data?.menuItemId
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
    }
  }

  return (
    <Modal
      title="Item info"
      open={props.visible}
      onCancel={props.handleCancel}
      footer={null}
      destroyOnClose={true}
    >
      <div className=" mb-2 font-semibold">
        <span className="font-bold ">Item: </span>
        {data?.menuName}
      </div>
      <div className="text-xs text-gray-500">
        {data?.modifier_order_items?.map((modifierGroup) => (
          <div key={modifierGroup.id}>
            {modifierGroup.name}:{" "}
            {modifierGroup.modifier
              ?.map((modifier) => modifier.name)
              .join(", ")}
          </div>
        ))}
      </div>
      <div className="mb-2 font-semibold">
        <span className="font-bold">Quantity: </span>
        {data?.noOfItems}
      </div>

      <div>
        <span className="font-bold">Ordered by: </span>
      </div>
      <div className="ml-5">
            {customerData?.orderBy?.map((order, index) => (
              <div key={index}>
                <div className="text-base font-semibold mb-1">{order.name}</div>
                {order.modifier_order_items.map((item, itemIndex) => (
                  <div
                    key={itemIndex}
                    className="text-xs text-gray-400 leading-5"
                  >
                    <div>
                      {item.name} :{" "}
                      {item.modifier.map((modifier, modifierIndex) => (
                        <span key={modifierIndex}>
                          {modifier.name}
                          {modifierIndex !== item.modifier.length - 1 && ", "}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
                <div className="text-base font-semibold mb-3 mt-1">
                  {order.note !== "" && (
                    <span className="font-bold">Note : </span>
                  )}
                  {order.note !== "" && order.note}
                </div>
              </div>
            ))}
          </div>
    </Modal>
  );
}

export default OrderedItemInfoModal;
