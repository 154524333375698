import React from "react";
import {
  StockOutlined,
  ContainerOutlined,
  WalletOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  QrcodeOutlined, MessageOutlined, MailOutlined
} from "@ant-design/icons";
import { currencyFormatter } from "../../helpers/currencyFormat";
import { GetTime } from "../../helpers/Date&Time";
import { useSelector } from "react-redux";
import { STATUS_VALUE } from "../../helpers/status";

const STATUS = {
  Available: "Available",
  Hidden: "Hidden",
  OutOfStock: "Out of stock",
};

const Card = ({
  time,
  title,
  position,
  category,
  status,
  actions,
  description,
  count,
  price,
  date,
  qrCode,
    email
}) => {
  const businessState = useSelector((state) => state.businessState.value);

  return (
    <div className="bg-white p-5 rounded-md cursor-pointer hover:shadow-md">
      <div className="flex justify-between mb-2">
        <div className="flex items-center">
          <div className="text-base font-semibold">
            <span className={"text-lg font-bold pr-1"}> {position} </span>
            {title}{" "}
            {category && (
              <span className="text-xs text-gray-400">( {category} )</span>
            )}
          </div>
        </div>
        <div className="text-base text-gray-400">
          {status && (
            <div
              className={`px-3 py-1 rounded-md border-2 text-xs mr-1
            ${STATUS_VALUE[status]["color"]}
            ${STATUS_VALUE[status]["borderColor"]}
            ${STATUS_VALUE[status]["textColor"]}
               `}
            >
              {STATUS_VALUE[status]["status"]}
            </div>
          )}
        </div>
      </div>
      <div className="flex  justify-between gap-x-5 gap-y-2">
        <div className="basis-2/3 grid grid-cols-2">
          {email && (
              <div className="flex items-center  gap-2">
                <MailOutlined
                    style={{ fontSize: "20px", color: "gray" }}
                />
                <div>{email}</div>
              </div>
          )}
          {date && (
            <div className="flex items-center  gap-2">
              <CalendarOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>{date}</div>
            </div>
          )}
          {time && (
            <div className="flex items-center  gap-2">
              <ClockCircleOutlined
                style={{ fontSize: "20px", color: "gray" }}
              />
              <div>{GetTime(time)}</div>
            </div>
          )}

          {count && (
            <div className="flex items-center  gap-2">
              <StockOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>{count}</div>
            </div>
          )}
          {description && (
            <div className="flex items-center  gap-2">
              <ContainerOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>{description}</div>
            </div>
          )}
          {price && (
            <div className="flex items-center  gap-2">
              <WalletOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>
                {currencyFormatter(price,businessState?.currency?.symbol)}
              </div>
            </div>
          )}
          {qrCode && (
            <div className="flex items-center  gap-2">
              <QrcodeOutlined style={{ fontSize: "20px", color: "gray" }} />
              <div>
               {qrCode}
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center  gap-2">{actions}</div>
      </div>
    </div>
  );
};

export default Card;
