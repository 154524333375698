import { Content } from "antd/es/layout/layout";
import React from "react";

function Employees() {
  return (
    <Content
      style={{
        margin: "16px",
        marginBottom: "0",
      }}
    >
      <div className="text-3xl">Employees contents here</div>
    </Content>
  );
}

export default Employees;
