export async function UploadImagesApi(file, name, folderName) {
  if (!file || !name) {
    throw new Error("File and name parameters are required.");
  }

  const formData = new FormData();

  formData.append("file", file, `${name}.png`);

  const token = "Bearer " + localStorage.getItem("authToken");

  const requestOptions = {
    method: "POST",

    headers: {
      Authorization: token,
    },

    body: formData,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}v1/file-upload?folderName=${folderName}`,

      requestOptions
    );

    if (!response.ok) {
      throw new Error("File upload failed.");
    }

    const responseData = await response.json();

    return responseData;
  } catch (error) {
    throw new Error(`File upload error: ${error.message}`);
  }
}
