import { Button, Form, Input, Select, Upload, message, Spin } from "antd";
import React, {useEffect, useState} from "react";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import { LoadingOutlined,EditOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import { UploadImagesApi } from "../../api/UploadImagesApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";
import EditBusinessApi from "../../api/Business/EditBusinessApi";
import { businessDetails } from "../../features/BusinessSlice";

const { Option } = Select;

function BusinessProfile() {
  const [submit, setSubmit] = useState(false);
  const [logo, setLogo] = useState("");
  const [newLogo, setNewLogo] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");
  const [newCoverPhoto, setNewCoverPhoto] = useState("");
  const [valuesChanged, setValuesChanged] = useState(false);
  const businessState = useSelector((state) => state.businessState.value);
  const dispatch = useDispatch();

  const left = (
      <>
        <div className="pri-font text-lg hidden md:block">Business Profile</div>
      </>
  );
  const fields = [
    {
      name: ["name"],
      value: businessState?.name || "",
    },
    {
      name: ["address"],
      value: businessState?.address || "",
    },
    {
      name: ["phoneNumber"],
      value: businessState?.phoneNumber?.substring(3) || "",
    },
    {
      name: ["siteUrl"],
      value: businessState?.siteUrl || "",
    },
    {
      name: ["description"],
      value: businessState?.description || "",
    },
  ];

  const prefixSelector = (
    <Form.Item name="phoneCode" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="94">+94</Option>
        <Option value="91">+65</Option>
      </Select>
    </Form.Item>
  );

  async function onFinish(values) {
    setSubmit(true);
    let phone = values.phoneNumber;
    if (phone.charAt(0) === "0") {
      phone = phone.slice(1);
    }
    const apiResponse = await EditBusinessApi(
      businessState?.id,
      values.name,
      values.address,
      values.phoneCode + phone,
      logo ? logo : businessState?.profile,
      coverPhoto ? coverPhoto : businessState?.coverPhoto ,
      values.description,
      values.siteUrl
    );
    if (apiResponse.error) {
      openNotificationError("bottomRight", apiResponse.message);
    } else if (apiResponse.success) {
      dispatch(businessDetails(apiResponse.data));
      openNotificationSuccess(
        "bottomRight",
        "Business profile successfully updated"
      );
    }
    setSubmit(false);
    setValuesChanged(false);
  }

  async function uploadLogo(file, name) {
    setSubmit(true);
    const apiResponse = await UploadImagesApi(file, name, "profile");
    if (apiResponse.success) {
      setLogo(apiResponse.url);
    }
    setSubmit(false);
  }

  function beforeCrop(file) {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      message.info("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.info("Image must smaller than 2MB!");
    }

    return isJpgOrPng && isLt2M;
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUploadLogo = async (info) => {
    await uploadLogo(info, businessState.id);
    getBase64(info, (logo) => {
      setNewLogo(logo);
    });
    return false;
  };

  const beforeUploadCoverPhoto = async (info) => {
    await uploadCoverPhoto(info, businessState.id);
    getBase64(info, (coverPhoto) => {
      setNewCoverPhoto(coverPhoto);
    });
    return false;
  };

  async function uploadCoverPhoto(file, name) {
    setSubmit(true);
    const apiResponse = await UploadImagesApi(file, name, "cover");
    if (apiResponse.success) {
      setCoverPhoto(apiResponse?.url);
    }
    setSubmit(false);
  }

  return (
      <>
        <div className="ml-4">
          <DashboardBreadcrumb left={left}/>
          <div className="bg-white p-4">
            <Form
                name="businessProfile"
                onValuesChange={() => {
                  setValuesChanged(true);
                }}
                fields={fields}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={true}
                initialValues={{
                  phoneCode: "+94",
                }}
                className="grid mt-2 gap-4 "
            >
              <div className="flex justify-center items-center w-full h-40 mt-1">
                <Form.Item
                    name="coverPhoto"
                    initialValue={businessState?.coverPhoto}
                >
                  <ImgCrop  rotate aspect={8 / 1} modalOk="Save" beforeCrop={beforeCrop} modalClassName='imageCrop'>
                    <Upload
                        name="coverPhoto"
                        listType={"picture"}
                        showUploadList={false}
                        beforeUpload={beforeUploadCoverPhoto}

                    >
                      {newCoverPhoto ||
                      coverPhoto ||
                      businessState?.coverPhoto ? (
                        <div className="relative h-full w-full overflow-hidden cursor-auto">
                            <img
                              src={
                                newCoverPhoto
                                    ? newCoverPhoto
                                    : coverPhoto
                                        ? coverPhoto
                                        : businessState?.coverPhoto
                              }
                              alt="avatar"
                              className="h-40 bg-gray-200 object-cover object-center w-full "
                          />
                           <div className="absolute top-0 left-0 w-full h-full hover:bg-gray-900 hover:bg-opacity-50 transition-opacity duration-300">
                              <div className="h-full w-full flex items-center justify-center opacity-0 hover:opacity-100  transition-opacity duration-300">
                                <EditOutlined className="text-red-400 text-2xl cursor-pointer"/>
                              </div> 
                            </div> 
                        </div>  
                      ) : (
                          <div className="flex justify-center items-center h-40 w-full bg-gray-200">
                        <span className="text-center mx-96">
                          {" "}
                          + Upload Cover Photo
                        </span>
                          </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </div>

              <div className="w-32 h-32   rounded-full flex justify-center items-center mx-auto -mt-20 border-4 border-white">
                <Form.Item name="profile" initialValue={businessState?.profile}>
                  <ImgCrop rotate aspect={4 / 4}  modalOk="Save" beforeCrop={beforeCrop} modalClassName='imageCrop'>
                    <Upload
                        name="logo"
                        listType="picture-circle"
                        showUploadList={false}
                        beforeUpload={beforeUploadLogo}
                    >
                      {newLogo || logo || businessState?.profile ? (
                        <div className="relative h-full w-full overflow-hidden rounded-full cursor-auto">
                            <img
                              src={
                                newLogo
                                    ? newLogo
                                    : logo
                                        ? logo
                                        : businessState?.profile
                              }
                              alt="avatar"
                              className="max-h-32 rounded-full bg-gray-200 border-4 border-white "
                          />
                          <div className="absolute top-0 left-0 w-full h-full hover:bg-gray-900 hover:bg-opacity-50 transition-opacity duration-300">
                            <div className="h-full w-full flex items-center justify-center opacity-0 hover:opacity-100  transition-opacity duration-300">
                              <EditOutlined className="text-red-400 text-2xl cursor-pointer"/>
                            </div> 
                          </div>
                        </div>   
                      ) : (
                          <div
                              className="flex justify-center items-center w-32 h-32 rounded-full bg-gray-200 border-4 border-white">
                            <span className="text-center"> + Upload</span>
                          </div>
                      )}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </div>

              <div className="max-w-xl">
                <Form.Item
                    name="name"
                    label="Restaurant Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter restaurant name!",
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name="address"
                    label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter address!",
                      },
                    ]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter phone number!",
                      },
                    ]}
                >
                  <Input addonBefore={prefixSelector} style={{width: "100%"}}/>
                </Form.Item>

                <Form.Item
                    name="siteUrl"
                    label="Site Url ( Optional )"
                    rules={[]}
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description ( Optional )"
                    rules={[
                      {
                        max: 500,
                        message: "Description must not exceed 500 characters.",
                      }
                    ]}
                >
                  <Input.TextArea/>
                </Form.Item>
                <Button
                    className="w-40 bg-blue-500"
                    type="primary"
                    htmlType="submit"
                >
                  {submit ? (
                      <Spin
                          size="small"
                          indicator={
                            <LoadingOutlined style={{color: "#fff"}} spin/>
                          }
                      />
                  ) : (
                      "Save"
                  )}
                </Button>
              </div>


            </Form>
          </div>
        </div>
      </>
  );
}

export default BusinessProfile;
