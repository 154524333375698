export default async function EditItemApi(
  id,
  name,
  isFixed,
  description,
  price,
  categoryId,
  imageUrl,
  removedModifierGroups,
  modifierGroups,
  
) {
  let res = "";
  try {
    const body = {
      id: id,
      name: name,
      isFixed:isFixed,
      description: description,
      price: price,
      categoryId: categoryId,
      imageUrl: imageUrl,
      removeModifierGroup: removedModifierGroups.map((modifier) => ({
        id: modifier.id,
        name: modifier.name,
        position: modifier.position,
      })),
      modifierGroups: modifierGroups.map((modifier) => ({
        id: modifier.id,
        name: modifier.name,
        position: modifier.position,
      })),
    };
    const token = "Bearer " + localStorage.getItem("authToken");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    };
    await fetch(`${process.env.REACT_APP_API_URL}v1/menu-item/`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error("Error editing menu-item: ", error);
        res = error;
      });
  } catch (error) {
    console.error(error);
    res = error;
  }

  return res;
}
