import React, { useState } from "react";
import { Form, Input, Button, Spin } from "antd";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import { Content } from "antd/lib/layout/layout";
import { LoadingOutlined } from "@ant-design/icons";
import ChangePasswordApi from "../../api/Auth/ChangePasswordApi";
import {
  openNotificationError,
  openNotificationSuccess,
} from "../../helpers/Notifications";

function ChangePassword() {
  const [submit, setSubmit] = useState(false);
  const [form] = Form.useForm();
  const proHeight = document.body.clientHeight;

  function showErrorMessage(message) {
    if (message === "Current password is incorrect") {
      form.setFields([
        {
          name: "currentPassword",
          errors: ["Current password is incorrect"],
        },
      ]);
    } else {
      openNotificationError(
        "bottomRight",
        "Couldn't setup profile. Try again later."
      );
    }
  }

  function strongPassword(password) {
    let strength = false;
    const passwordArray = password.split("");
    let lowercase = false;
    let uppercase = false;
    let number = false;
    let length = false;
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    passwordArray.map((pass) => {
      if (pass.match(lowerCaseLetters)) {
        lowercase = true;
      }
    });
    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    passwordArray.map((pass) => {
      if (pass.match(upperCaseLetters)) {
        uppercase = true;
      }
    });
    // Validate numbers
    const numbers = /[0-9]/g;
    passwordArray.map((pass) => {
      if (pass.match(numbers)) {
        number = true;
      }
    });
    // Validate length
    if (passwordArray.length >= 8) {
      length = true;
    }
    if (lowercase && uppercase && number && length) {
      strength = true;
    }

    return strength;
  }

  async function onFinish(values) {
    setSubmit(true);
    if (!strongPassword(values.newPassword)) {
      form.setFields([
        {
          name: "newPassword",
          errors: ["Enter a strong password!"],
        },
      ]);
    } else if (values.newPassword !== values.confirmPassword) {
      form.setFields([
        {
          name: "confirmPassword",
          errors: ["Password doesn't match!"],
        },
      ]);
    } else {
      const apiResponse = await ChangePasswordApi(
        values.currentPassword,
        values.newPassword,
        values.confirmPassword
      );
      if (apiResponse.error) {
        showErrorMessage(apiResponse.message);
      } else if (apiResponse.data?.username) {
        form.resetFields();
        openNotificationSuccess("bottomRight", apiResponse.message);
      } else {
        form.resetFields();
        openNotificationError(
          "bottomRight",
          `Password change Failed. Try again later}`
        );
      }
    }
    setSubmit(false);
  }

  const left = (
    <>
      <div className="pri-font text-lg hidden md:block">Change password</div>
    </>
  );

  return (
    <>
      <div className="ml-4">
        <DashboardBreadcrumb left={left} />
        <Content className="bg-white p-4"
          style={{
            height: proHeight - 122,
          }}
        >
          <div
            className="custom-scroll"
          >
            <div className="max-w-xl">
              <Form
                name="changePassword"
                onFinish={onFinish}
                form={form}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
              >
                <Form.Item
                  name="currentPassword"
                  label="Old Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter current password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  label="New Password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter new password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  label="Confirm Password"
                  rules={[
                    {
                      required: true,
                      message: "Please re enter password!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <div
                  style={{
                    borderColor: "#e5e7eb",
                  }}
                  className=" text-gray-600 bg-blue-100 p-2 rounded-md mb-6 text-xs"
                >
                  Your password must be at least 8 characters long, contain at
                  least one number, one uppercase letter and one lowercase
                  letter.
                </div>
                <Button
                  className="w-40 bg-blue-500"
                  type="primary"
                  htmlType="submit"
                >
                  {submit ? (
                    <Spin
                      size="small"
                      indicator={
                        <LoadingOutlined style={{ color: "#fff" }} spin />
                      }
                    />
                  ) : (
                    "Update password"
                  )}
                </Button>
              </Form>
            </div>
          </div>
        </Content>
      </div>
    </>
  );
}

export default ChangePassword;
