import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import {
  AppstoreOutlined,
  ReadOutlined,
  ProfileOutlined,
  CoffeeOutlined,
  QrcodeOutlined,
  PicCenterOutlined,
  GatewayOutlined,
  SettingOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { GetAllActiveOrderApi } from "../../api/Workspace/GetAllActiveOrderApi";
import { STATUS } from "../../helpers/constants";
import io from "socket.io-client";
import notificationSound from "../../assets/sounds/liveNotification.mp3";

function getItem(label, key, icon, children, type, hasActiveOrders = false) {
  return {
    key,
    icon,
    children,
    label,
    type,
    hasActiveOrders,
  };
}

const items = [
  getItem(<Link to="/dashboard">Dashboard</Link>, "0", <AppstoreOutlined />),
  getItem(
    <Link to="/workspace">Workspace</Link>,
    "1",
    <PicCenterOutlined />,
    null,
    null,
    true
  ),
  getItem(<Link to="/orders">Orders</Link>, "2", <ProfileOutlined />),
  getItem(<Link to="/categories">Categories</Link>, "3", <ReadOutlined />),
  getItem(<Link to="/items">Items</Link>, "4", <CoffeeOutlined />),
  getItem(
    <Link to="/modifiers">Modifier Groups</Link>,
    "5",
    <FileAddOutlined />
  ),
  getItem(<Link to="/tables">Tables</Link>, "6", <GatewayOutlined />),
  getItem(<Link to="/qr-codes">QR Code</Link>, "7", <QrcodeOutlined />),
  getItem(<Link to="/settings">Settings</Link>, "8", <SettingOutlined />),
  // getItem(<Link to="/employees">Employees</Link>, "5", <UserOutlined />),
  // getItem("Reports", "7", <FileZipOutlined />, [
  //   getItem("Option 1", "7sub1"),
  //   getItem("Option 2", "7sub2"),
  // ]),
];

const paths = [
  { path: "/dashboard", key: "0" },
  { path: "/workspace", key: "1" },
  { path: "/orders", key: "2" },
  { path: "/categories", key: "3" },
  { path: "/items", key: "4" },
  { path: "/modifiers", key: "5" },
  { path: "/tables", key: "6" },
  { path: "/qr-codes", key: "7" },
  { path: "/settings", key: "8" },

  // { path: "/employees", key: "5" },
];

function DashboardSideMenu(props) {
  const [mainKey, setMainKey] = useState("");
  const [data, setData] = useState();
  const [acceptOrderData, setAcceptOrderData] = useState();
  const [rejectOrderData, setRejectOrderData] = useState();
  const [closeOrderData, setCloseOrderData] = useState([]);
  const socket = io(`${process.env.REACT_APP_API_URL}`);
  let curLocation = useLocation();
  const [playNotificationSound, setPlayNotificationSound] = useState(false);
  const [audio] = useState(new Audio(notificationSound));

  useEffect(() => {
    getAllActiveOrder();
  }, [
    props.createData,
    props.confirmData,
    props.addCustomerData,
    props.rejectData,
    props.acceptData,
    props.completeData,
    acceptOrderData,
    rejectOrderData,
    closeOrderData,
    props.enableCustomerAdminData,
    props.enableCustomerByAnotherData,
    props.disableCustomerAdminData,
    props.disableCustomerByAnotherData,
  ]);

  const acceptOrder = (data) => {
    setAcceptOrderData(data?.data);
  };

  const rejectOrder = (data) => {
    setRejectOrderData(data?.data);
  };

  const closeOrder = (data) => {
    setCloseOrderData(data?.data);
  };

  useEffect(() => {
    socket?.on("acceptOrder", acceptOrder);
    socket?.on("rejectOrder", rejectOrder);
    socket?.on("closeOrder", closeOrder);

    return () => {
      socket?.off("acceptOrder", acceptOrder);
      socket?.off("rejectOrder", rejectOrder);
      socket?.off("closeOrder", closeOrder);
    };
  }, []);

  useEffect(() => {
    setMainKey("");
    paths.map((path) => {
      if (path.path === curLocation.pathname) {
        setMainKey(path.key);
      } else if (curLocation.pathname.split("/")[1] == "orders") {
        setMainKey("2");
      } else if (curLocation.pathname.split("/")[1] == "settings") {
        setMainKey("8");
      } else if (curLocation.pathname.split("/")[1] == "workspace") {
        setMainKey("1");
      }
    });
  }, [curLocation]);

  useEffect(() => {
    if (playNotificationSound && props.soundEnabled) {
      audio.play();
    }
  }, [
    playNotificationSound,
    props.createData,
    props.confirmData,
    props.addCustomerData,
    props.completeData,
  ]);

  async function getAllActiveOrder() {
    const apiResponse = await GetAllActiveOrderApi();
    if (apiResponse.success) {
      setData(apiResponse.data);
    }
  }

  function renderAllActiveOrders() {
    let activeCount = 0;
  
    const filteredOrders = data?.filter(
      (order) =>
        order?.orderStatus === STATUS.Active ||
        order?.orderStatus === STATUS.Completed
    );
  
    filteredOrders?.forEach((order) => {
      if (!order.statusFullyAccepted) {
        activeCount++;
      }
    });
  
    if (activeCount === 0) {
      return null;
    }
  
    return (
      <div
        className="relative flex items-center"
        onAnimationStart={() => setPlayNotificationSound(true)}
        onAnimationEnd={() => setPlayNotificationSound(false)}
      >
        <span className="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-red-500 opacity-75 mr-2"></span>
        <span className="relative inline-flex rounded-full h-4 w-4 bg-red-500 mr-2">
          <span
            className="absolute inset-0 flex items-center justify-center text-xs text-white"
            style={{ zIndex: 10 }}
          >
            {activeCount}
          </span>
        </span>
      </div>
    );
  }

  function renderAllRequestOrders() {
    let requestCount = 0;
    data?.forEach((order) => {
      if (
        !order.statusFullyAccepted &&
        order.orderStatus === STATUS.Requested
      ) {
        requestCount++;
      }
    });
  
    if (requestCount === 0) {
      return null;
    }
  
    return (
      <div
        className="relative flex items-center"
        onAnimationStart={() => setPlayNotificationSound(true)}
        onAnimationEnd={() => setPlayNotificationSound(false)}
      >
        <span className="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-red-500 opacity-75 mr-2"></span>
        <span className="relative inline-flex rounded-full h-4 w-4 bg-red-500 mr-2">
          <span
            className="absolute inset-0 flex items-center justify-center text-xs text-white"
            style={{ zIndex: 10 }}
          >
            {requestCount}
          </span>
        </span>
      </div>
    );
  }
  

  return (
    <Menu selectedKeys={[mainKey]} mode="inline" style={{ border: "none"}}>
      {items.map((item) => (
        <React.Fragment key={item.key}>
          <Menu.Item key={item.key} icon={item.icon} style={{paddingRight:'0px'}}>
            <div className="flex items-center justify-between">
              {item.label}
              {item.key === "1" && item.hasActiveOrders && (
                <div className="flex items-center">
                  {renderAllActiveOrders()}
                  {renderAllRequestOrders()}
                </div>
              )}
            </div>
          </Menu.Item>
        </React.Fragment>
      ))}
    </Menu>
  );
}

export default DashboardSideMenu;


