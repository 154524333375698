import { Button, Descriptions, Space, Spin, Table, Tag, theme, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import {
  PlusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  StopOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import DashboardBreadcrumb from "../../components/layout/DashboardBreadcrumb";
import AddDiscountModal from "../../components/modals/add/AddDiscountModal";
import { useLocation, useNavigate } from "react-router-dom";
import GetWorkspaceOrderItemApi from "../../api/Workspace/GetWorkspaceOrderItemApi";
import { GetTime } from "../../helpers/Date&Time";
import MainLoader from "../../Loader/MainLoader";
import WorkspaceItemInfoModal from "../../components/modals/WorkspaceItemInfoModal";
import { STATUS } from "../../helpers/constants";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import io from "socket.io-client";
import CustomersAccessModal from "../../components/modals/CustomersAccessModal";
import { GetCustomersStatusApi } from "../../api/Workspace/GetCustomersStatusApi";
import { useSelector } from "react-redux";
import { currencyFormatter } from "../../helpers/currencyFormat";
import DataCard from "../../components/widgets/DataCard";

const { useToken } = theme;

function WorkspaceItem(props) {
  const { token } = useToken();
  const [addModal, setAddModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [canStart, setCanStart] = useState(false);
  const [data, setData] = useState();
  const [infoData, setInfoData] = useState();
  const [closeModal, setCloseModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [customersAccessModal, setCustomersAccessModal] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [closeOrderData, setCloseOrderData] = useState([]);
  const [acceptOrderModal, setAcceptOrderModal] = useState(false);
  const [rejectOrderModal, setRejectOrderModal] = useState(false);
  const [cancelOrderData, setCancelOrderData] = useState([]);
  const [loading, setLoading] = useState({});
  const socket = io(`${process.env.REACT_APP_API_URL}`);
  const businessState = useSelector((state) => state.businessState.value);
  const [acceptAllLoading, setAcceptAllLoading] = useState(false);

  const curLocation = useLocation();
  const navigate = useNavigate();

  const path = curLocation.pathname;
  const id = path.split("/");

  useEffect(() => {
    getOrderItems();
    accessCustomers();
  }, [
    props.confirmData,
    props.acceptData,
    props.rejectData,
    props.cancelData,
    props.enableCustomerAdmin,
    props.disableCustomerAdmin,
    props.enableCustomerByAnother,
    props.disableCustomerByAnother,
    closeOrderData,
    props.addCustomerData,
    cancelOrderData,
  ]);

  const sendAcceptOrderItem = (record) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [record.menuItemId]: true,
    }));
    socket?.emit("acceptOrderItem", {
      orderId: id[2],
      menuItemId: record.menuItemId,
    });
  };

  const sendRejectOrderItem = (record) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [record.menuItemId]: true,
    }));
    socket?.emit("rejectOrderItem", {
      orderId: id[2],
      menuItemId: record.menuItemId,
    });
  };

  useEffect(() => {
    setLoading(false);
  }, [data]);

  const sendCloseOrder = () => {
    socket?.emit("closeOrder", { id: id[2] });
  };

  const sendCancelOrder = () => {
    socket?.emit("cancelOrder", { id: id[2] });
  };

  const closeOrder = (data) => {
    setCloseOrderData(data?.data);
    navigate(`/orders`);
  };

  const cancelOrder = (data) => {
    setCancelOrderData(data?.data);
    navigate(`/orders`);
  };

  const sendAcceptOrder = () => {
    socket?.emit("acceptOrder", { orderId: id[2] });
  };

  const sendRejectOrder = () => {
    socket?.emit("rejectOrder", { orderId: id[2] });
  };

  const sendAcceptAllOrderItems = (menuItemIds) => {
    socket?.emit("acceptAllOrderItems", { orderId: id[2], menuItemIds });
  };

  useEffect(() => {
    socket?.on("closeOrder", closeOrder);
    socket?.on("cancelOrder", cancelOrder);

    return () => {
      socket?.off("closeOrder", closeOrder);
      socket?.off("cancelOrder", cancelOrder);
    };
  }, []);

  async function getOrderItems() {
    const apiResponse = await GetWorkspaceOrderItemApi(id[2]);
    if (apiResponse.success) {
      setData(apiResponse.data);
      setCanStart(true);
    }
  }

  async function accessCustomers() {
    const apiResponse = await GetCustomersStatusApi(id[2]);
    if (apiResponse.success) {
      setCustomerData(apiResponse.data);
    }
  }

  const acceptedOrderItems = data?.orderItems?.orderItemAcceptedData
    ? data?.orderItems?.orderItemAcceptedData
    : [];
  const rejectedOrderItems = data?.orderItems?.orderItemRejectedData
    ? data?.orderItems?.orderItemRejectedData
    : [];
  const confirmedOrderItems = data?.orderItems?.orderItemConfirmedData
    ? data?.orderItems?.orderItemConfirmedData
    : [];
  const updatedOrders = acceptedOrderItems.map((item) => ({
    ...item,
    price: currencyFormatter(item.price, businessState?.currency?.symbol),
  }));
  const updatedRejectedOrders = rejectedOrderItems.map((item) => ({
    ...item,
    price: currencyFormatter(item.price, businessState?.currency?.symbol),
  }));

  const updatedConfirmedOrders = confirmedOrderItems.map((item) => ({
    ...item,
    price: currencyFormatter(item.price, businessState?.currency?.symbol),
  }));
  const dataArray = [
    ...updatedOrders,
    ...updatedRejectedOrders,
    ...updatedConfirmedOrders,
  ];

  useEffect(() => {
    // Check if all confirmed items are accepted
    const allConfirmedAccepted = dataArray.every(
      (item) => item.status === STATUS.Accepted
    );

    // If all confirmed items are accepted, set loading to false and disable the button
    if (allConfirmedAccepted) {
      setAcceptAllLoading(false);
    }
  }, [dataArray]);

  const columns = [
    {
      title: "Item",
      dataIndex: "menuName",
      key: "menuName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          {text === STATUS.Confirmed ? (
            <Tag color="yellow">Confirmed</Tag>
          ) : text === STATUS.Accepted ? (
            <Tag color="green">Accepted</Tag>
          ) : (
            <Tag color="red">Rejected</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "No of items",
      dataIndex: "noOfItems",
      key: "noOfItems",
    },

    {
      title: "Sub total",
      dataIndex: "price",
      key: "totalPrice",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      fixed: "right",
      width: 140,
      ellipsis: true,
      render: (text, record) => (
        <Space size="middle">
          {(record.status === STATUS.Confirmed ||
            record.status === STATUS.Rejected) && (
            <Tooltip
              title={loading[record.menuItemId] ? "Processing" : "Accept"}
            >
              <Button
                style={{ borderColor: "green" }}
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  sendAcceptOrderItem(record);
                }}
                icon={<CheckCircleOutlined style={{ color: "green" }} />}
                loading={loading[record.menuItemId]}
                disabled={
                  props.completeData?.finalInvoice ||
                  data?.status === STATUS.Completed
                }
              />
            </Tooltip>
          )}
          {(record.status === STATUS.Confirmed ||
            record.status === STATUS.Accepted) && (
            <Tooltip
              title={loading[record.menuItemId] ? "Processing" : "Reject"}
            >
              <Button
                danger
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  sendRejectOrderItem(record);
                }}
                icon={<CloseCircleOutlined />}
                loading={loading[record.menuItemId]}
                disabled={
                  props.completeData?.finalInvoice ||
                  data?.status === STATUS.Completed
                }
              />
            </Tooltip>
          )}
          <Tooltip title={"Cancel"}>
            <Button
              danger
              size="small"
              icon={<DeleteOutlined />}
              disabled={
                props.completeData?.finalInvoice ||
                data?.status === STATUS.Completed
              }
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const right = (
    <>
      {data?.status === STATUS.Requested ? (
        <>
          <div className="flex gap-3">
            <Button
              className="text-green-500 bg-transparent flex justify-center items-center border-none"
              onClick={() => setAcceptOrderModal(true)}
              size="large"
              icon={
                <CheckSquareOutlined
                  style={{ fontSize: "30px", color: "#22C55E" }}
                />
              }
            />
            <Button
              size="large"
              className="text-red-500 flex justify-center items-center border-none"
              onClick={() => setRejectOrderModal(true)}
              icon={
                <CloseSquareOutlined
                  style={{ fontSize: "30px", color: "#EF4444" }}
                />
              }
            />
          </div>
        </>
      ) : (
        <>
          {props.completeData?.finalInvoice ||
          data?.status === STATUS.Completed ? (
            <Button
              className="mr-3 bg-blue-500"
              type="primary"
              onClick={() => setCloseModal(true)}
            >
              Close
            </Button>
          ) : (
            <Button className="mr-3" disabled block>
              Close
            </Button>
          )}

          <Button danger onClick={() => setCancelModal(true)}>
            <StopOutlined />
          </Button>
        </>
      )}
    </>
  );
  const left = <div className="pri-font text-lg">Workspace / {data?.id}</div>;
  return (
    <>
      <div className="my-4">
        <DashboardBreadcrumb left={left} right={right} />
        {canStart ? (
          <>
            <div className=" mb-4">
              <div className=" grid grid-cols-1 md:grid-cols-2 gap-5">
                <div className="bg-white p-5 rounded-md ">
                  <Descriptions title="Order Details">
                    <Descriptions.Item
                      label="Qr Id"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.qrId}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Table"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.tableDetails?.name}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Order Id"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.id}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Total Items"
                      labelStyle={{ color: "gray" }}
                    >
                      {data?.noOfItems}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Ordered Time"
                      labelStyle={{ color: "gray" }}
                    >
                      {GetTime(data?.orderedOn)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="No Of Pax"
                      labelStyle={{ color: "gray" }}
                    >
                      <>
                        <Space align="center" size="small">
                          <p>{data?.customersCount}</p>

                          <Tooltip title="Info">
                            <Button
                              type="primary"
                              ghost
                              size="small"
                              onClick={() => setCustomersAccessModal(true)}
                              icon={<InfoCircleOutlined />}
                            />
                          </Tooltip>
                        </Space>
                      </>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="bg-white p-5 rounded-md">
                  <Descriptions title="Price Details">
                    <Descriptions.Item
                      label="Sub Total"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.subTotal,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Tax"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.tax,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Service Charge"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.serviceCharge,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Discount"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.discount,
                        businessState?.currency?.symbol
                      )}
                      <span
                        className={"pl-2"}
                        style={{ color: token.colorPrimary, cursor: "pointer" }}
                        onClick={() => setAddModal(true)}
                      >
                        <Tooltip title={"Add discount"}>
                          <PlusCircleOutlined />
                        </Tooltip>
                      </span>
                    </Descriptions.Item>

                    <Descriptions.Item
                      label="Grand Total"
                      className=" font-semibold"
                      labelStyle={{ color: "gray" }}
                    >
                      {currencyFormatter(
                        data?.grandTotal,
                        businessState?.currency?.symbol
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            </div>
          </>
        ) : (
          <MainLoader />
        )}
        <div className="flex justify-between items-center mb-2">
          <div className="text-lg font-semibold ">Items</div>
          <Button
            type="primary"
            className="w-24 bg-blue-500"
            onClick={() => {
              const menuItemIds = confirmedOrderItems.map(
                (item) => item.menuItemId
              );
              setAcceptAllLoading(true);
              sendAcceptAllOrderItems(menuItemIds);
            }}
            disabled={
              !(
                dataArray.length > 0 &&
                dataArray.some((item) => item.status === STATUS.Confirmed)
              )
            }
          >
            {acceptAllLoading ? (
              <Spin
                size="small"
                indicator={<LoadingOutlined style={{ color: "#fff" }} spin />}
              />
            ) : (
              "Accept All"
            )}
          </Button>
        </div>

        <Table
          className="hidden sm:block"
          dataSource={dataArray}
          columns={columns}
          rowClassName={"cursor-pointer"}
          onRow={(record) => {
            return {
              onClick: () => {
                setInfoModal(true);
                setInfoData(record);
              },
            };
          }}
        />

        <div className={"sm:hidden grid grid-cols-1 gap-2"}>
          {acceptedOrderItems.map((items) => (
            <DataCard
              key={items?.menuItemId}
              title={items?.menuName}
              price={items?.price}
              count={items?.noOfItems}
              status={items?.status}
            />
          ))}
        </div>

        <AddDiscountModal
          data={data}
          visible={addModal}
          handleCancel={() => setAddModal(false)}
          handleOk={() => {
            getOrderItems();
            setAddModal(false);
          }}
        />
        <WorkspaceItemInfoModal
          acceptData={props.acceptData}
          data={infoData}
          visible={infoModal}
          handleCancel={() => setInfoModal(false)}
          status={
            props.completeData?.finalInvoice ||
            data?.status === STATUS.Completed
          }
        />
        <ConfirmationModal
          visible={closeModal}
          handleOk={() => {
            sendCloseOrder();
            setCloseModal(false);
          }}
          handleCancel={() => setCloseModal(false)}
          text="Are you sure want to close this order?"
          title="Close order"
        />
        <ConfirmationModal
          visible={cancelModal}
          handleOk={() => {
            sendCancelOrder();
            setCancelModal(false);
          }}
          handleCancel={() => setCancelModal(false)}
          text="Are you sure want to cancel this order?"
          title="Cancel order"
        />
        <CustomersAccessModal
          visible={customersAccessModal}
          data={customerData}
          handleCancel={() => setCustomersAccessModal(false)}
        />
        <ConfirmationModal
          visible={acceptOrderModal}
          handleCancel={() => setAcceptOrderModal(false)}
          handleOk={() => {
            sendAcceptOrder();
            setAcceptOrderModal(false);
          }}
          text="Are you sure want to accept this order?"
          title="Accept order"
        />
        <ConfirmationModal
          visible={rejectOrderModal}
          handleCancel={() => setRejectOrderModal(false)}
          handleOk={() => {
            sendRejectOrder();
            setRejectOrderModal(false);
          }}
          text="Are you sure want to reject this order?"
          title="Reject order"
        />
      </div>
    </>
  );
}

export default WorkspaceItem;
