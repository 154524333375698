export default async function AddModifierApi( name, isRequired, minimum, maximum, modifiers) {
  let res = "";
  try {
    const body = {
      name: name,
      isRequired: isRequired,
      minimum: minimum,
      maximum: maximum,
      modifiers: modifiers.map((modifier) => ({
        name: modifier.name,
        isPreSelected: modifier.isPreSelected,
        price: modifier.price,
      })),
    };

    const token = "Bearer " + localStorage.getItem("authToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    };
    await fetch(`${process.env.REACT_APP_API_URL}v1/modifier`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        res = response;
      })
      .catch((error) => {
        console.error("Error add modifier: ", error);
        res = error;
      });
  } catch (error) {
    console.error(error);
    res = error;
  }

  return res;
}
